import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Accordion, Form, Icon, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import DeleteOption from "./DeleteOption";
import QuestionRecursive from "./QuestionsRecursive";

const UpdateOption = ({
  option,
  handleClick,
  activeIndex,
  num,
  type,
  refetch,
  optionQuestionValidation,
  idSurvey
}) => {
  const [response, setResponse] = useState(option?.response || "");
  const [order, setOrder] = useState(option?.order || "1");
  const [optionQuestion, setOptionQuestion] = useState(
    option?.optionQuestion || false
  );
  const [question, setQuestion] = useState(option?.questionText || "");
  const [loading, setLoading] = useState(false);
  const onCompleted = ({ updateOption }) => {
    setResponse(updateOption?.response);
    setOrder(updateOption?.order);
    setOptionQuestion(updateOption?.optionQuestion);
    setQuestion(updateOption?.questionText);
    setLoading(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(false);
    const input = {
      id: option?.id,
      response,
      order: parseInt(order),
      optionQuestion,
    };

    if (optionQuestion) input.questionText = question;
    mutation({ variables: { input } });
  };
  const disabled = () => {
    if (!response || !response?.trim()) return true;
    if (!order || parseInt(order) <= 0) return true;
    if (optionQuestion) {
      if (!question || !question?.trim()) return true;
    }
    if (loading) return true;
  };

  return (
    <Segment>
      <Accordion.Title
        onClick={handleClick}
        index={num}
        active={activeIndex === num}
      >
        <Label
          style={{ backgroundColor: "#045A73", color: "white" }}
          attached={activeIndex === num ? "top left" : "top"}
        >
          <Icon name="dropdown" />
          {option?.order}. {option?.response}
        </Label>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === num}>
        <>
          <Form.Group widths="equal">
            <Form.Input
              label="Respuesta"
              value={response}
              onChange={(e, { value }) => setResponse(value)}
              placeholder="Respuesta"
            />
            <Form.Input
              label="Orden"
              type="number"
              min={1}
              onChange={(e, { value }) => setOrder(value)}
              value={order}
              placeholder="Orden"
            />
          </Form.Group>
          {type === "Radio" && (
            <Form.Checkbox
              checked={optionQuestion}
              onChange={(e, { checked }) => setOptionQuestion(checked)}
              toggle
              label="Opción pregunta"
            />
          )}
          {type === "Radio" && optionQuestion && (
            <Form.Input
              label="Pregunta"
              value={question}
              onChange={(e, { value }) => setQuestion(value)}
              placeholder="Pregunta"
            />
          )}
          <Form.Group inline widths="equal">
            {optionQuestionValidation() && <QuestionRecursive idOption={option.id} count={1} idSurvey={idSurvey}/>}
            
            <Form.Button
              size="mini"
              fluid
              onClick={handleMutation}
              loading={loading}
              disabled={disabled()}
              style={{ backgroundColor: "#045A73", color: "white" }}
              content="Guardar"
            />
            <DeleteOption idOption={option?.id} refetch={refetch} />
          </Form.Group>
        </>
      </Accordion.Content>
    </Segment>
  );
};
const UPDATE_OPTION = gql`
  mutation ($input: UpdateOptionInput!) {
    updateOption(input: $input) {
      id
      order
      response
      questionText
      optionQuestion
    }
  }
`;

export default UpdateOption;
