import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import moment from "moment";
import Swal from "sweetalert2";
import ModalDetails from "./FamilyPlanModal";

const FamilyPlanHistory = ({ permissions, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [idPeriod, setIdPeriod] = useState("");
  const [idFamilyPlan, setIdFamilyPlan] = useState("");
  const [fakeName, setFakeName] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState("ACTIVE");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const filter = { status };
  if (idPeriod.trim()) filter.idPeriod = idPeriod;
  if (idFamilyPlan.trim()) filter.idFamilyPlan = idFamilyPlan;
  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      filter.initialDate = `${initialDate} 00:00:00`;
      filter.finalDate = `${finalDate} 23:59:59`;
    }
  }

  const { data, loading, error } = useQuery(FAMILY_PLAN_HISTORY, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Encuestador",
      accessor: "user.name",
    },
    {
      header: "Miembro familiar",
      accessor: "familyMenmber",
      render: ({ firstName, secondName, surname, secondSurname }) =>
        `${firstName} ${secondName} ${surname} ${secondSurname}`,
    },
    {
      header: "Fecha inicio",
      accessor: "startDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Fecha fin",
      accessor: "endDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Plan familiar",
      accessor: "familyPlan.name",
    },
    {
      header: "Periodo",
      accessor: "period.name",
    },
    {
      header: "Fecha inicio (periodo)",
      accessor: "period.startDate",
    },
    {
      header: "Fecha fin (periodo)",
      accessor: "period.endDate",
    },
    {
      header: "Respondio",
      accessor: "answered",
      render: (answered) => {
        if (typeof answered === "boolean" && answered === true) return "Si";
        if (typeof answered === "boolean" && answered === false) return "No";
      },
    },
    {
      header: "Detalle",
      accessor: "familyPlanDetailQuestionAnswer",
      render: (familyPlanDetailQuestionAnswer) => (
        <ModalDetails data={familyPlanDetailQuestionAnswer} />
      ),
    },
  ];

  const clearFilter = () => {
    setFakeName("");
    setIdPeriod("");
    setIdFamilyPlan("");
    setCurrentPage(1);
    setDataFiltered([]);
    setInitialDate("");
    setFinalDate("");
  };

  const searchName = (fakeName) => {
    setDataFiltered(
      data?.familyPlanAnswerHeaderList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(fakeName?.toLowerCase())
      )
    );
  };

  return (
    <div>
      <Form>
        <Form.Group inline>
          <Form.Dropdown
            label="Plan Familiar"
            placeholder="Búsqueda por Plan familiar"
            value={idFamilyPlan}
            scrolling
            clearable
            onChange={(e, { value }) => setIdFamilyPlan(value)}
            options={data?.familyPlanList?.items}
          />
          <Form.Dropdown
            label="Periodo"
            placeholder="Búsqueda por periodo"
            value={idPeriod}
            scrolling
            clearable
            onChange={(e, { value }) => setIdPeriod(value)}
            options={data?.periodList?.items}
          />
          <Form.Input
            type="date"
            size="mini"
            value={initialDate}
            onChange={(e, { value }) => setInitialDate(value)}
            label="Fecha Inicial"
          />
          <Form.Input
            type="date"
            size="mini"
            value={finalDate}
            onChange={(e, { value }) => setFinalDate(value)}
            label="Fecha Final"
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field>
            <Label
              content={`Cantidad (${
                dataFiltered.length > 0
                  ? dataFiltered.length
                  : data?.familyPlanAnswerHeaderList?.count || 0
              })`}
            />
          </Form.Field>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por encuestador"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.familyPlanAnswerHeaderList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.familyPlanAnswerHeaderList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};
const FAMILY_PLAN_HISTORY = gql`
  query ($page: PageInput!, $filter: FamilyPlanFilter!) {
    familyPlanAnswerHeaderList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        startDate
        endDate
        user {
          id
          name
        }
        familyPlan {
          id
          name
        }
        familyMenmber {
          id
          firstName
          secondName
          surname
          secondSurname
        }
        period {
          id
          name
          startDate
          endDate
        }
        familyPlanDetailQuestionAnswer {
          id
          enunciated
          nameQuestion
          familyPlanDetailAnswers {
            answer
            answerQuestion
          }
        }
        answered
      }
    }
    familyPlanList(page: $page, filter: { status: ACTIVE }) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        key: id
        text: name
      }
    }
    periodList(page: $page, type: FAMILYPLAN) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default FamilyPlanHistory;
