import { useQuery, gql } from "@apollo/client";
import { Form } from "semantic-ui-react";

const InputsFamily = ({
  maritalStatus,
  setMaritalStatus,
  gender,
  clearable,
  search = true,
  selection = true,
  setGender,
  required = true,
  typeIdentification,
  setTypeIdentification,
  nationality,
  setNationality,
  regularization,
  disabled = false,
  setRegularization,
}) => {
  const { loading, error, data } = useQuery(DATA);

  return (
    <>
      <Form.Dropdown
        clearable={clearable}
        required={required}
        scrolling
        placeholder="Filtrar por tipo identificación"
        selection={selection}
        search={search}
        disabled={disabled}
        value={typeIdentification}
        onChange={(e, { value }) => setTypeIdentification(value)}
        loading={loading}
        error={error}
        options={data?.typeIdentification?.items || []}
        label="Tipo identificación"
      />
      <Form.Dropdown
        required={required}
        scrolling
        placeholder="Filtrar por género"
        clearable={clearable}
        selection={selection}
        search={search}
        disabled={disabled}
        value={gender}
        onChange={(e, { value }) => setGender(value)}
        loading={loading}
        error={error}
        options={data?.gender?.items || []}
        label="Género"
      />
      <Form.Dropdown
        required={required}
        scrolling
        placeholder="Filtrar por estado civil"
        clearable={clearable}
        disabled={disabled}
        selection={selection}
        search={search}
        value={maritalStatus}
        onChange={(e, { value }) => setMaritalStatus(value)}
        loading={loading}
        error={error}
        options={data?.maritalStatus?.items || []}
        label="Estado civil"
      />
      <Form.Dropdown
        required={required}
        disabled={disabled}
        scrolling
        placeholder="Filtrar por nacionalidad"
        clearable={clearable}
        selection={selection}
        search={search}
        value={nationality}
        onChange={(e, { value }) => setNationality(value)}
        loading={loading}
        error={error}
        options={data?.nationality?.items || []}
        label="Nacionalidad"
      />
      {nationality === "67" && (
        <Form.Dropdown
          required={required}
          disabled={disabled}
          scrolling
          placeholder="¿En qué parte del proceso de regularización se encuentra?"
          clearable={clearable}
          selection={selection}
          search={search}
          value={regularization}
          onChange={(e, { value }) => setRegularization(value)}
          loading={loading}
          error={error}
          options={data?.regularization?.items || []}
          label="Proceso de regularización"
        />
      )}
    </>
  );
};

const DATA = gql`
  query {
    maritalStatus: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "2" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    typeIdentification: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "3" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    gender: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "4" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    nationality: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "15" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    regularization: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "16" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default InputsFamily;
