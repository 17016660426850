import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import {
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Message,
  Segment,
} from "semantic-ui-react";

const filterTypeText = {
  ID: {
    placeholder: "Buscar lider por identificación",
    message: "Digite la identificación para su busqueda.",
  },
  NAME: {
    placeholder: "Buscar lider por nombre",
    message: "Digite un nombre para su busqueda.",
  },
  HOUSE_NUMBER: {
    placeholder: "Buscar lider por número de casa",
    message: "Digite un número de casa para su busqueda.",
  },
};

const LeaderInput = ({ leader, setLeader, disabled = false }) => {
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("ID");
  const [fakeSearch, setFakeSearch] = useState("");
  const variables = {
    search: search.trim() ? true : false,
  };
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data } = useQuery(FAMILY_LEADERS, {
    variables,
    fetchPolicy: "network-only",
  });

  const handleDeleteSearch = () => {
    setSearch("");
    setFakeSearch("");
  };

  const handleFilterType = (e, { value }) => {
    setFilterType(value);
    setSearch("");
    setFakeSearch("");
  };

  const handleCleanItem = () => {
    setLeader(null);
    setSearch("");
    setFakeSearch("");
  };

  return (
    <>
      <Form style={{ padding: "1rem" }}>
        {leader && (
          <List relaxed divided>
            <List.Item>
              <List.Content floated="right">
                {!disabled && (
                  <Icon
                    onClick={handleCleanItem}
                    size="large"
                    name="delete"
                    color="red"
                    link
                  />
                )}
              </List.Content>
              <List.Icon name="user" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a">{leader?.name}</List.Header>
                <List.Description as="a">
                  <Icon name="home" />
                  {leader?.houseNumber}
                </List.Description>
                <List.Description as="a">
                  <Icon name="address card" />
                  {leader?.identification}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        )}
        {!leader && (
          <>
            <Dropdown
              value={filterType}
              onChange={handleFilterType}
              options={[
                { text: "Identificación", value: "ID", key: 1 },
                { text: "Nombre", value: "NAME", key: 2 },
                { text: "# Casa", value: "HOUSE_NUMBER", key: 3 },
              ]}
            />
            <Input
              fluid
              iconPosition={fakeSearch.trim() ? "left" : null}
              icon={
                fakeSearch.trim()
                  ? {
                      name: "delete",
                      circular: true,
                      link: true,
                      onClick: () => handleDeleteSearch(),
                    }
                  : null
              }
              placeholder={filterTypeText[filterType]?.placeholder}
              value={fakeSearch}
              action={{
                content: "Buscar",
                icon: "search",
                style: { backgroundColor: "#045A73", color: "white" },
                onClick: (e) => {
                  e.preventDefault()
                  setSearch(fakeSearch);
                },
              }}
              onChange={(e, { value }) => setFakeSearch(value)}
            />
          </>
        )}
        {data?.familyLeaders?.length === 0 && !leader && !loading && (
          <Message>No se encontró el lider.</Message>
        )}
        {loading && <h3>Buscando...</h3>}
        {error && <Message negative>Ha ocurrido un error.</Message>}
        {data?.familyLeaders?.length > 0 && !leader && (
          <Segment style={{ height: 150, overflow: "auto" }}>
            <h5>Seleccionar lider familiar</h5>
            <List relaxed divided>
              {data?.familyLeaders?.map((familyLeader) => (
                <List.Item
                  onClick={() => setLeader(familyLeader)}
                  key={familyLeader?.id}
                >
                  <List.Icon name="user" size="large" verticalAlign="middle" />
                  <List.Content>
                    <List.Header as="a">{familyLeader?.name}</List.Header>
                    <List.Description as="a">
                      <Icon name="home" />
                      {familyLeader?.houseNumber}
                    </List.Description>
                    <List.Description as="a">
                      <Icon name="address card" />
                      {familyLeader?.identification}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        )}
      </Form>
    </>
  );
};

const FAMILY_LEADERS = gql`
  query (
    $name: String
    $identification: String
    $houseNumber: String
    $search: Boolean!
  ) {
    familyLeaders(
      name: $name
      identification: $identification
      houseNumber: $houseNumber
    ) @include(if: $search) {
      id
      identification
      name
      houseNumber
    }
  }
`;

export default LeaderInput;
