import React, { useState } from "react";
import { Form, Message, Modal, Tab } from "semantic-ui-react";

import MemberInput from "./MemberInput";

const MemberModal = ({ answerHeader, refetch, edit }) => {
  const [open, setOpen] = useState(false);

  if (!answerHeader.detailQuestionAnswers.length)
    return (
      <div style={{ overflowX: "overlay" }}>
        <Message>La encuesta no ha sido respondida totalmente.</Message>
      </div>
    );
  if (!edit)
    return (
      <div style={{ overflowX: "overlay" }}>
        <Message>
          Usted no tiene permiso para editar un historial de encuesta.
        </Message>
      </div>
    );

  const panes = [
    {
      menuItem: "Miembro",
      render: () => (
        <Form>
          <MemberInput
            refetch={refetch}
            answerHeader={answerHeader}
            open={open}
            setOpen={setOpen}
          />
        </Form>
      ),
    },
  ];
  return (
    <Modal
      trigger={
        <Form.Button onClick={() => setOpen(true)} icon="edit" size="tiny" />
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Actualizar miembro familiar</Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
    </Modal>
  );
};

export default MemberModal;
