import React, { useState } from "react";
import { Button, Form, Dropdown, DropdownMenu } from "semantic-ui-react";
import { gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
const Created = ({ refetch }) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el equipo de trabajo de manera exitosa.",
    }).then(() => setOpen(false));
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_WORKTEAM, { onCompleted, onError });

  const capitalizar = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleMutation = () => {
    let input = "";
    if (name.trim()) input = capitalizar(name);
    mutation({ variables: { input } });
  };

  return (
    <>
      <Dropdown
        icon="add"
        as={Button}
        size="tiny"
        open={open}
        onClick={() => setOpen(true)}
      >
        <DropdownMenu style={{ padding: 10 }}>
          <Form style={{ marginBottom: 10 }}>
            <Form.Input
              required
              label="Nombre"
              value={name}
              onChange={(_, { value }) => setName(value)}
            />
          </Form>
          <Button
            content="Guardar"
            disabled={!name.trim()}
            onClick={handleMutation}
          />
          <Button
            content="Cancelar"
            onClick={() => {
              setOpen(false);
            }}
          />
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const CREATE_WORKTEAM = gql`
  mutation ($input: String!) {
    createWorkTeam(name: $input) {
      id
    }
  }
`;

export default Created;
