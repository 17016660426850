import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Label, Modal, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import CustomTable from "../../../Components/CustomTable";

function Offers({ idProgram, offers, refetch, error, loading, EDIT }) {
  const [open, setOpen] = useState(false);
  const [, setOpenDelete] = useState(false);
  const [offer, setOffer] = useState("");
  const [idOffers, setIdOffers] = useState(0);
  const [, setSearch] = useState("");
  const variables = {
    page: { number: 1, limit: 10000 },
    filter: { status: "ACTIVE", name: "" },
  };
  const { data } = useQuery(OFFER_LIST, {
    variables,
    fetchPolicy: "network-only",
  });
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompletedDelete = async () => {
    setSearch("");
    setIdOffers(0);
    setOpenDelete(false);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha agregado la oferta de manera exitosa.",
    });
  };
  const onCompleted = async () => {
    setSearch("");
    setIdOffers(0);
    setOpenDelete(false);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha agregado la oferta de manera exitosa.",
    });
  };
  const [mutation] = useMutation(ADD_PROGRAM_OFFER, { onCompleted, onError });
  const [deleteMutation] = useMutation(DELETE_PROGRAM_OFFER, {
    onCompleted: onCompletedDelete,
    onError,
  });

  const handleMutation = () => {
    const input = {
      idProgram,
      idOffers,
    };
    mutation({ variables: { input } });
  };

  const handleMutationDelete = (id) => {
    const input = {
      idProgram,
      idOffers: id,
    };
    deleteMutation({ variables: { input } });
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Descripción",
      accessor: "description",
    },
  ];

  /* const { data } = useQuery(OFFER_LIST, {
    variables: {},
    fetchPolicy: "network-only",
  });
*/
  if (EDIT)
    columns.push({
      header: "Acción de eliminar",
      render: ({ id }) => (
        <div>
          <Button
            onClick={() => {
              Swal.fire({
                title: "¿Estás seguro?",
                text: "No podrás revertir esta acción.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, eliminar",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleMutationDelete(id);
                }
              });
            }}
            size="mini"
          >
            Eliminar
          </Button>
        </div>
      ),
    });

  return (
    <>
      <Button
        size="tiny"
        compact
        icon="eye"
        content="Ver Ofertas"
        onClick={() => setOpen(true)}
      />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Ofertas</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label content={`Cantidad (${offers?.length || 0})`} />
              </Form.Field>
              <Form.Input
                action={{
                  icon: "trash",
                  size: "mini",
                  content: "Limpiar",
                  onClick: () => setOffer(""),
                }}
                value={offer}
                size="mini"
                onChange={(e, { value }) => setOffer(value)}
                placeholder="Búsqueda por nombre"
              />
              {EDIT && (
                <Popup
                  content={
                    <Form.Group>
                      <Form.Dropdown
                        search
                        selection
                        clearable
                        className="Search"
                        loading={loading}
                        placeholder="Ofertas"
                        onResultSelect={(_, data) =>
                          setIdOffers(data.result.id)
                        }
                        onChange={(_, data) => setIdOffers(data.value)}
                        onSearchChange={(_, data) => setSearch(data.value)}
                        options={data?.offerList?.items.map((data) => ({
                          key: data.id,
                          text: data.name,
                          value: data.id,
                        }))}
                        value={idOffers}
                      />
                      <div style={{ marginTop: 10 }} />
                      <Form.Button
                        content="Guardar"
                        loading={loading}
                        disabled={idOffers === 0 || loading}
                        onClick={handleMutation}
                      />
                    </Form.Group>
                  }
                  on="click"
                  positionFixed
                  trigger={<Form.Button icon="add" size="tiny" compact />}
                />
              )}
            </Form.Group>
          </Form>
          <CustomTable
            totalPages={1}
            data={
              offers.filter((item) =>
                item.name
                  .toLowerCase()
                  .includes(offer.toLocaleLowerCase().trim())
              ) || []
            }
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

const ADD_PROGRAM_OFFER = gql`
  mutation ($input: inputProgramOffers!) {
    addProgramOffer(input: $input) {
      id
      name
      description
      status
    }
  }
`;

const DELETE_PROGRAM_OFFER = gql`
  mutation ($input: inputProgramOffers!) {
    deleteProgramOffer(input: $input) {
      id
      name
      description
      status
    }
  }
`;

const OFFER_LIST = gql`
  query ($page: PageInput!, $filter: OfferFilter!) {
    offerList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        description
        status
      }
    }
  }
`;

export default Offers;
