import React, { useState } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

const ParameterValues = ({ reload, values }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    await reload();
    setOpen(true);
  };

  return (
    <div>
      <Button icon labelPosition="right" size="mini" onClick={handleOpen}>
        <Icon name="search" />
        {values?.length}
      </Button>
      <Modal
        onClose={() => setOpen(false)}
        style={{ width: "auto" }}
        open={open}
        centered
      >
        <Modal.Content>
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Orden</Table.HeaderCell>
                <Table.HeaderCell>Valor parámetro</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {values?.length > 0 && (
              <Table.Body>
                {Array.from(values)
                  ?.sort((a, b) => (a.order < b.order ? -1 : 1))
                  .map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.order}</Table.Cell>
                      <Table.Cell>{item.name}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            )}
          </Table>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ParameterValues;
