import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputsFamily from "./InputsFamily";

const CreateFamilyLeader = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [surname, setSurname] = useState("");
  const [secondSurname, setSecondSurname] = useState("");
  const [identification, setIdentification] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [typeIdentification, setTypeIdentification] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [expeditionDocumentDate, setExpeditionDocumentDate] = useState("");
  const [expirationDocumentDate, setExpirationDocumentDate] = useState("");
  const [stage, setStage] = useState("");
  const [encuestador, setEncuestador] = useState(null);
  const { loadingUsers, errorUsers, data } = useQuery(USERS, {
    variables: {
      page: {
        number: 1,
        limit: 10000,
      },
      filter: {
        status: "ACTIVE",
        idUserGroup: 2,
      },
    },
    fetchPolicy: "network-only",
  });

  const disabled = () => {
    if (!firstName.trim()) return true;
    if (!surname.trim()) return true;
    if (!identification.trim()) return true;
    if (!birthDate.trim()) return true;
    if (!gender.trim()) return true;
    if (!maritalStatus.trim()) return true;
    if (!typeIdentification.trim()) return true;
    if (loading) return true;
    if (!stage.trim()) return true;
    if (!houseNumber.trim()) return true;
  };
  const onCompleted = async () => {
    if (typeof refetch === "function") await refetch();
    setFirstName("");
    setSecondName("");
    setSecondSurname("");
    setSurname("");
    setIdentification("");
    setBirthDate("");
    setPhone("");
    setMail("");
    setTypeIdentification("");
    setGender("");
    setMaritalStatus("");
    setExpeditionDocumentDate("");
    setExpirationDocumentDate("");
    setHouseNumber("");
    setStage("");
    setEncuestador(null);
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el usuario de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_FAMILY_LEADER, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    if (new Date(birthDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de nacimiento es inválida.",
      });
    } else if (new Date(expeditionDocumentDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de expedición del documento es inválida.",
      });
    } else {
      setLoading(true);
      const input = {
        identification,
        idTypeIdentification: typeIdentification,
        firstName,
        secondName,
        surname,
        secondSurname,
        birthDate,
        idGender: gender,
        idMaritalStatus: maritalStatus,
        idEncuestador: encuestador,
        etapa: stage,
        houseNumber,
      };
      if (phone.trim()) input.phone = phone;
      if (mail.trim()) input.mail = mail;
      if (expirationDocumentDate.trim())
        input.expirationDocumentDate = expirationDocumentDate;
      if (expeditionDocumentDate.trim())
        input.expeditionDocumentDate = expeditionDocumentDate;
      mutation({ variables: { input } });
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={{ backgroundColor: "#045A73", color: "white" }}
        content="Crear lider familiar"
      />
      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear lider familiar</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                value={firstName}
                onChange={(e, { value }) => setFirstName(value)}
                label="Primer nombre"
              />
              <Form.Input
                value={secondName}
                onChange={(e, { value }) => setSecondName(value)}
                label="Segundo nombre"
              />
              <Form.Input
                required
                value={surname}
                onChange={(e, { value }) => setSurname(value)}
                label="Primer apellido"
              />
              <Form.Input
                value={secondSurname}
                onChange={(e, { value }) => setSecondSurname(value)}
                label="Segundo apellido"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                value={identification}
                onChange={(e, { value }) => setIdentification(value)}
                label="Identificación"
              />
            </Form.Group>
            <InputsFamily
              maritalStatus={maritalStatus}
              setMaritalStatus={setMaritalStatus}
              gender={gender}
              setGender={setGender}
              typeIdentification={typeIdentification}
              setTypeIdentification={setTypeIdentification}
            />
            <Form.Input
              value={stage}
              onChange={(e, { value }) => setStage(value)}
              label="Barrio"
              required
            />
            <Form.Dropdown
              label="Encuestador"
              value={encuestador}
              onChange={(e, { value }) => setEncuestador(value)}
              scrolling={true}
              clearable={true}
              selection={true}
              error={errorUsers}
              search={true}
              loading={loadingUsers}
              options={
                data?.userList?.items.map((item) => {
                  return { ...item, value: item.id, text: item.name };
                }) || []
              }
            />
            <Form.Group widths="equal">
              <Form.Input
                value={houseNumber}
                required
                onChange={(e, { value }) => setHouseNumber(value)}
                label="Dirección"
              />
              <Form.Input
                value={mail}
                onChange={(e, { value }) => setMail(value)}
                label="Correo (opcional)"
              />
              <Form.Input
                value={phone}
                onChange={(e, { value }) => setPhone(value)}
                label="Teléfono (opcional)"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                type="date"
                value={birthDate}
                onChange={(e, { value }) => setBirthDate(value)}
                label="Fecha de nacimiento"
              />
              <Form.Input
                type="date"
                value={expeditionDocumentDate}
                onChange={(e, { value }) => setExpeditionDocumentDate(value)}
                label="Fecha de expedición del documento (Opcional)"
              />
              <Form.Input
                type="date"
                value={expirationDocumentDate}
                onChange={(e, { value }) => setExpirationDocumentDate(value)}
                label="Fecha de vencimiento (Opcional)"
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={disabled()}
            onClick={handleMutation}
            loading={loading}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Guardar"
          />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_FAMILY_LEADER = gql`
  mutation ($input: CreateFamilyLeaderInput!) {
    createFamilyLeader(input: $input) {
      id
    }
  }
`;

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
  }
`;
export default CreateFamilyLeader;
