import { useQuery, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import moment from "moment";
import Swal from "sweetalert2";
import ModalDetails from "./SurveyModal";
import UpdateSurveyModal from "./UpdateSurveyModal";
import MemberModal from "./MemberModal";
import DeleteAnswerHistory from "./Delete";

const SurveyHistory = ({ permissions, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [idPeriod, setIdPeriod] = useState("");
  const [idSurvey, setIdSurvey] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState("ACTIVE");
  const [fakeName, setFakeName] = useState("");
  const [fakeMemberName, setFakeMemberName] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");

  const [filter, setFilter] = useState({ status });

  useEffect(() => {
    if (idPeriod.trim()) filter.idPeriod = idPeriod;
    if (idSurvey.trim()) filter.idSurvey = idSurvey;
    //eslint-disable-next-line
  }, [idPeriod, idSurvey]);

  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      filter.initialDate = `${initialDate} 00:00:00`;
      filter.finalDate = `${finalDate} 23:59:59`;
    }
  }
  const { data, loading, error, refetch } = useQuery(HISTORY_SURVEY, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
    //eslint-disable-next-line
  }, [filter]);

  const columns = [
    {
      header: "Encuestador",
      accessor: "user.name",
    },
    {
      header: "Miembro familiar",
      accessor: "familyMenmber",
      render: ({ firstName, secondName, surname, secondSurname }) =>
        `${firstName || ''} ${secondName ? ` ${secondName}` : ''} ${surname ? ` ${surname}` : ''} ${secondSurname ? ` ${secondSurname}` : ''}`,
    },
    {
      header: "Fecha inicio",
      accessor: "startDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Fecha fin",
      accessor: "endDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Encuesta",
      accessor: "survey.name",
    },
    {
      header: "Periodo",
      accessor: "period.name",
    },
    {
      header: "Fecha inicio (periodo)",
      accessor: "period.startDate",
    },
    {
      header: "Fecha fin (periodo)",
      accessor: "period.endDate",
    },
    {
      header: "Respondio",
      accessor: "answered",
      render: (answered) => {
        if (typeof answered === "boolean" && answered === true) return "Si";
        if (typeof answered === "boolean" && answered === false) return "No";
      },
    },
    {
      header: "Editar",
      render: (answerHeader) => (
        <UpdateSurveyModal
          edit={EDIT}
          data={answerHeader.detailQuestionAnswers}
          idAnswerHeader={answerHeader.id}
          refetch={refetch}
        />
      ),
    },
    {
      header: "Detalle",
      accessor: "detailQuestionAnswers",
      render: (detailQuestionAnswers) => (
        <ModalDetails data={detailQuestionAnswers} />
      ),
    },
    {
      header: "Transferir encuesta",
      accesor: "",
      render: (data) => (
        <MemberModal edit={EDIT} answerHeader={data} refetch={refetch} />
      ),
    },
    {
      header: "Eliminar encuesta",
      accesor: "",
      render: (data) => (
        <DeleteAnswerHistory
          edit={EDIT}
          answerHeader={data}
          refetch={refetch}
        />
      ),
    },
  ];

  const clearFilter = () => {
    setFakeName("");
    setIdPeriod("");
    setIdSurvey("");
    setCurrentPage(1);
    setDataFiltered([]);
    setInitialDate("");
    setFinalDate("");
    setFilter({ status: "ACTIVE" });
  };

  const searchNameEncuestador = (fakeName) => {
    setFilter({ ...filter, pollster: fakeName });
  };
  const searchNameMiembroFamiliar = (fakeName) => {
    setFilter({ ...filter, member: fakeName });
  };

  return (
    <div>
      <Form>
        <Form.Group inline>
          <Form.Dropdown
            label="Encuesta"
            placeholder="Búsqueda por Encuesta"
            value={idSurvey}
            scrolling
            clearable
            onChange={(e, { value }) => setIdSurvey(value)}
            options={data?.surveyList?.items}
          />
          <Form.Dropdown
            label="Periodo"
            placeholder="Búsqueda por periodo"
            value={idPeriod}
            scrolling
            clearable
            onChange={(e, { value }) => setIdPeriod(value)}
            options={data?.periodList?.items}
          />
          <Form.Input
            type="date"
            size="mini"
            value={initialDate}
            onChange={(e, { value }) => setInitialDate(value)}
            label="Fecha Inicial"
          />
          <Form.Input
            type="date"
            size="mini"
            value={finalDate}
            onChange={(e, { value }) => setFinalDate(value)}
            label="Fecha Final"
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field>
            <Label
              content={`Cantidad (${
                dataFiltered.length > 0
                  ? dataFiltered.length
                  : data?.answerHeaderList?.count || 0
              })`}
            />
          </Form.Field>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchNameEncuestador(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por encuestador"
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchNameMiembroFamiliar(fakeMemberName),
            }}
            value={fakeMemberName}
            size="mini"
            onChange={(e, { value }) => setFakeMemberName(value)}
            placeholder="Búsqueda por miembro familiar"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.answerHeaderList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.answerHeaderList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};
const HISTORY_SURVEY = gql`
  query ($page: PageInput!, $filter: AnswerHeaderFilter!) {
    answerHeaderList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        startDate
        endDate
        user {
          id
          name
        }
        survey {
          id
          name
        }
        familyMenmber {
          id
          firstName
          secondName
          surname
          secondSurname
        }
        period {
          id
          name
          startDate
          endDate
        }
        detailQuestionAnswers {
          id
          enunciated
          nameQuestion
          detailAnswers {
            id
            answer
            answerQuestion
          }
        }
        answered
      }
    }
    surveyList(page: $page, filter: { status: ACTIVE }) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        key: id
        text: name
      }
    }
    periodList(page: $page, type: SURVEY) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default SurveyHistory;
