export const getData = (key) => {
  const data = localStorage.getItem(key);
  if (!data) return null;

  return JSON.parse(data);
};

export const parseDataToJson = data => {
  return JSON.parse(JSON.stringify(data));
}

export const setData = (key, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
};

export const parseStatus = value => {
  switch (value) {
    case "ACTIVE":
      return "Activo";
    case "INACTIVE":
      return "Inactivo";
    case "REMOVED":
      return "Eliminado";
    default:
      return "- - - -";
  }
}

export const upperCaseInitialEachWord = word => {
  return word.toLowerCase()
    .trim()
    .split(' ')
    .map(v => v[0].toUpperCase() + v.substr(1))
    .join(' ');
};

export const parseTypeOptions = value => {
  switch (value) {
    case "Opcion":
      return "Opción";
    case "Multiple-Opcion":
      return "Múltiple opción"
    default:
      return value;
  }
}

export const parseStatusDownload = value => {
  switch (value) {
    case "INPROGRESS":
      return "En progreso";
    case "GENDERED":
      return "Generado"
    default:
      return "- - - -";
  }
};

export const calculateAge = birthday => {
  if (!birthday) return null;
  birthday = new Date(birthday);
  let ageDifMs = Date.now() - birthday.getTime();
  let ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

