import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Icon, Input, List, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const ConvertToLeaderModal = ({ familyMember }) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [houseNumber, setHouseNumber] = useState("");
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = () => {
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
    }).then(() => setOpen(false));
  }
  const [mutation] = useMutation(CONVERT_TO_LEADER, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        idFamilyMember: familyMember?.id,
        houseNumber
      }
    });
  }

  return (
    <Modal
      size="tiny"
      open={open}
      trigger={
        <Button
          compact
          size="tiny"
          onClick={() => setOpen(true)}
          content="Convertir en lider" />}
      onClose={() => setOpen(false)}>
      <Modal.Header>
        Convertir en lider a
      </Modal.Header>
      <Modal.Content>
        <List relaxed divided>
          <List.Item>
            <List.Icon name='user' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as='a'>{familyMember?.name}</List.Header>
              <List.Description as='a'>
                <Icon name="address card" />
                {familyMember?.identification}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        <Input
          type="text"
          label="# Casa"
          onChange={(e, { value }) => setHouseNumber(value)} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          onClick={handleMutation}
          disabled={!houseNumber.trim() || loading}
          style={{ backgroundColor: "#045A73", color: "white" }}
          content="Convertir" />
        <Button onClick={() => setOpen(false)} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  );
}

const CONVERT_TO_LEADER = gql`
mutation($idFamilyMember: ID!, $houseNumber: String!) {
  convertToLeader(idFamilyMember: $idFamilyMember, houseNumber: $houseNumber) {
    id
    name
    isLeader
    identification
    familyLeader {
      id
      name
      identification
      houseNumber
    }
  }
}
`;


export default ConvertToLeaderModal;