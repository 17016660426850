import React from "react";
import { Button, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";

const TokenValue = ({ reload, values }) => {
  const handleOpen = async () => {
    await reload();
    Swal.fire({
      title: "Token de la sesión",
      text: values,
    });
  };

  return (
    <div>
      <Button icon size="mini" onClick={handleOpen}>
        <Icon name="search" />
      </Button>
    </div>
  );
};

export default TokenValue;
