import { gql, useMutation } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, Table, Message, Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const HeaderAnswers = ({ form, setForm }) => {
  return (
    <Table striped stackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{ backgroundColor: "#045A73", color: "white" }}
          >
            Pregunta
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{ backgroundColor: "#045A73", color: "white" }}
          >
            Respuesta
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {form.map((answer, key) => (
          <Fragment key={`answer${answer.id}`}>
            <Table.Row>
              <Table.Cell>{form[key]?.question?.enunciated}</Table.Cell>
              <Table.Cell>
                <Form.Input
                  value={form[key]?.answer}
                  onChange={(e, { value }) => {
                    let actualArray = form;
                    actualArray[key].answer = value;
                    setForm([...actualArray]);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            {answer.answerQuestion && (
              <Table.Row>
                <Table.Cell>{form[key]?.question?.nameQuestion}</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    value={form[key]?.answerQuestion}
                    onChange={(e, { value }) => {
                      let actualArray = form;
                      actualArray[key].answerQuestion = value;
                      setForm([...actualArray]);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );
};

const ModalDetails = ({ data = [], edit, idAnswerHeader, refetch }) => {
  const onCompleted = () => {
    Swal.fire({
      icon: "success",
      title: "La encuesta fue actualizada exitosamente.",
    }).then(() => {
      refetch();
      setOpen(false);
    });
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_ANSWERS, { onCompleted, onError });
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(
    Array.prototype.concat.apply(
      [],
      data.map((e) =>
        e.detailAnswers.map((e2) => {
          return { ...e2, question: { ...e, detailAnswers: undefined } };
        })
      )
    )
  );

  useEffect(() => {
    setForm(
      Array.prototype.concat.apply(
        [],
        data.map((e) =>
          e.detailAnswers.map((e2) => {
            return { ...e2, question: { ...e, detailAnswers: undefined } };
          })
        )
      )
    );
  }, [open]);

  if (!data.length)
    return (
      <div style={{ overflowX: "overlay" }}>
        <Message>No hay datos.</Message>
      </div>
    );

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <Button
          size="tiny"
          compact
          disabled={!edit}
          onClick={() => setOpen(true)}
          content="editar"
        />
      }
    >
      <Modal.Header>Actualizar respuestas</Modal.Header>
      <Modal.Content scrolling>
        <HeaderAnswers form={form} setForm={setForm} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Guardar"
          onClick={() => {
            mutation({
              variables: {
                input: {
                  idAnswerHeader: idAnswerHeader,
                  detailAnswers: form.map((e) => {
                    return {
                      id: e.id,
                      answer: e.answer,
                      answerQuestion: e.answerQuestion,
                    };
                  }),
                },
              },
            });
          }}
        />
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

const UPDATE_ANSWERS = gql`
  mutation ($input: UpdateAnswersInput!) {
    updateAnswers(input: $input) {
      id
    }
  }
`;

export default ModalDetails;
