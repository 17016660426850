import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import DragAndDrop from "../../../../../../../../Components/DragAndDrop";
import CreateQuestion from "./CreateQuestion.js";
import DeleteQuestion from "./DeleteQuestion";
import Options from "./options";
import TypeQuestion from "./TypeQuestion.js";

export const Questions = ({
  questions = [],
  actionCreate,
  actionUpdate,
  actionDelete=true,
  idCategory,
  idSurvey,
  refetch,
  idOption,
  count=1
}) => {
  const [stateQuestions, setStateQuestions] = useState(questions || []);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setStateQuestions(questions);
  }, [questions]);
  const handleQuestion = (currentQuestion) => {
    const newQuestions = [...stateQuestions];
    const index = newQuestions.findIndex(question => question?.id === currentQuestion?.id);
    if (index !== -1) {
      newQuestions[index] = currentQuestion;
      setStateQuestions(newQuestions);
    }
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = async () => {
    setLoading(false);
  };
  const [mutation] = useMutation(UPDATE_FAMILY_PLAN_QUESTIONS, { onCompleted, onError });
  const goTop = () => {
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  };
  const saveQuestions = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          questions: stateQuestions?.map((question, i) => ({
            id: question?.id,
            name: question?.name,
            enunciated: question?.enunciated,
            order: i + 1,
            idType: question?.type?.id,
            required: question?.required
          }))
        }
      }
    });
  }

  return (
    <>
      <div >
        {(stateQuestions.length > 3) && (
          <CreateQuestion
          idSurvey={idSurvey}
            idOption={idOption}
            idCategory={idCategory}
            refetch={refetch} />
        )}
        <DragAndDrop data={stateQuestions} onChange={res => setStateQuestions(res)}>
          {({ element, index }) => (
            <Question
              refetch={refetch}
              index={index}
              idSurvey={idSurvey}
              question={element}
              actionCreate={actionCreate}
              actionUpdate={actionUpdate}
              actionDelete={actionDelete}
              handleQuestion={handleQuestion}
              count={count} />
          )}
        </DragAndDrop>
          <CreateQuestion
            idSurvey={idSurvey}
            idOption={idOption}
            idCategory={idCategory}
            refetch={refetch} />
      </div>
      <br />
      {(stateQuestions.length > 0) && (
        <Button
          loading={loading}
          disabled={loading}
          content="Guardar"
          onClick={saveQuestions} />
      )}
      {scrollPosition > 150 && (
        <Button
          content="Volver arriba"
          icon="angle double up"
          floated="right"
          onClick={goTop} />
      )}
    </>
  );
}

const Question = ({
  question,
  index,
  handleQuestion,
  idSurvey,
  refetch,
  actionDelete,
  actionUpdate,
  actionCreate,
  count
}) => {
  const [stateQuestion, setStateQuestion] = useState(question || {});
  useEffect(() => {
    setStateQuestion(question);
  }, [question])
  const onChange = (e, { value, name }) => {
    const newStateQuestion = { ...stateQuestion, [name]: value };
    setStateQuestion(newStateQuestion);
    if (typeof handleQuestion === "function") handleQuestion(newStateQuestion);
  }

  const optionsValidation = () => {
    switch (question?.type?.id) {
      case "2":
        return true;
      case "24":
        return true;
      case "25":
        return true;
      default:
        return false;
    }
  };

  const optionQuestionValidation = () => question?.type?.id === "24" || question?.type?.id === "25";

  return (
    <Segment style={{ marginTop: 10 }}>
      {actionDelete && (
        <div style={{ textAlign: 'right' }}>
          <DeleteQuestion
            refetch={refetch}
            id={question?.id} />
        </div>
      )}
      <br />
      <Label attached="top left">
        {index + 1}
      </Label>
      <Label attached="bottom right">
        {question?.type?.name}
      </Label>
      <Form>
        <Form.Group widths={2}>
          <Form.Input
            fluid
            label="Nombre"
            name="name"
            value={stateQuestion?.name}
            onChange={onChange} />
          <Form.Input
            fluid
            label="Enunciado"
            name="enunciated"
            value={stateQuestion?.enunciated}
            onChange={onChange} />
        </Form.Group>
        <TypeQuestion
          fluid
          name="type"
          placeholder="Seleccionar algún tipo de pregunta"
          selection
          onChange={(e, data) => onChange(e, { ...data, value: { ...stateQuestion?.type, id: data?.value } })}
          value={stateQuestion?.type?.id}
          label="Tipo de pregunta" />
        <Form.Field>
          <label>Obligatorio</label>
          <Checkbox
            toggle
            checked={stateQuestion?.required}
            onChange={(e, data) => onChange(e, { ...data, value: data.checked })}
            name="required" />
        </Form.Field>
      </Form>
      <br />
      {optionsValidation() && (
        <Options
          idSurvey={idSurvey}
          question={question}
          refetch={refetch}
          actionCreate={actionCreate}
          actionUpdate={actionUpdate}
          actionDelete={actionDelete}
          options={stateQuestion?.options}
          optionQuestionValidation={optionQuestionValidation}
          count={count}/>
      )}
    </Segment>
  );
}

const UPDATE_FAMILY_PLAN_QUESTIONS = gql`
  mutation($input: UpdateQuestionsInput!) {
    updateQuestions(input: $input) {
      id
      name
      required
      order
      type {
        id
        name
      }
      enunciated
    }
  }
`;

export default Questions;