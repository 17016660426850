import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateFamilyPlan = ({ refetch }) => {
  const [name, setName] = useState("");
  const [idWorkTeam, setIdWorkTeam] = useState("");
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DATA, {
    fetchPolicy: "network-only",
  });
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    setName("");
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_FAMILY_PLAN, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({ variables: { name, idWorkTeam } });
  };
  return (
    <Segment>
      <div style={{ textAlign: "center" }}>
        <Popup
          content={
            <>
              <Form>
                <Form.TextArea
                  value={name}
                  onChange={(e, { value }) => setName(value)}
                  style={{ minHeight: 75, maxHeight: 75 }}
                  label="Nombre"
                />
                <Form.Dropdown
                  search
                  selection
                  clearable
                  size="mini"
                  required
                  options={data?.workTeamListByUser || []}
                  placeholder="Equipo de Trabajo"
                  value={idWorkTeam}
                  label="Equipo de Trabajo"
                  onChange={(e, { value }) => setIdWorkTeam(value)}
                />
                <Button
                  loading={loading}
                  disabled={!name.trim() || loading}
                  onClick={handleMutation}
                  content="Crear plan familiar"
                />
              </Form>
            </>
          }
          on="click"
          positionFixed
          trigger={<Button icon="add" circular />}
        />
      </div>
    </Segment>
  );
};

const CREATE_FAMILY_PLAN = gql`
  mutation ($name: String!, $idWorkTeam: ID!) {
    createFamilyPlan(name: $name, idWorkTeam: $idWorkTeam) {
      id
    }
  }
`;

const DATA = gql`
  query {
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

export default CreateFamilyPlan;
