import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Label, Modal, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import CustomTable from "../../../Components/CustomTable";

function Afiliado({ idProject, refetch, error, loading, EDIT }) {
  const [open, setOpen] = useState(false);
  const [beneficiary, setBeneficiary] = useState("");
  const [name, setName] = useState("");
  const [idFamilyMenmber, setIdFamilyMenmber] = useState(0);
  const [, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompletedDelete = async () => {
    setSearch("");
    setIdFamilyMenmber(0);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el afiliado de manera exitosa.",
    });
  };
  const onCompleted = async () => {
    setSearch("");
    setIdFamilyMenmber(0);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el afiliado de manera exitosa.",
    });
  };
  const [mutation] = useMutation(ADD_FAMILY_MEMBERS, { onCompleted, onError });
  const [deleteMutation] = useMutation(DELETE_FAMILY_MEMBERS, {
    onCompleted: onCompletedDelete,
    onError,
  });

  const handleMutation = () => {
    const input = {
      idProject,
      idFamilyMenmber,
    };
    mutation({ variables: { input } });
  };

  const handleMutationDelete = (id) => {
    const input = {
      idProject,
      idFamilyMenmber: id,
    };
    deleteMutation({ variables: { input } });
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Número de telefono",
      accessor: "phone",
    },
    {
      header: "Dirección",
      accessor: "address",
    },
    {
      header: "Identificacion",
      accessor: "identification",
      //render: ({ documents }) => <DocumentList data={documents} compact />,
    },
  ];

  const { data } = useQuery(FAMILY_MEMBERS, {
    variables: {
      page: { number: 1, limit: 10 },
      filter: {
        name,
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: beneficiaries } = useQuery(PROYECT_FAMILY_MEMBERS, {
    variables: {
      page: { number: currentPage, limit: 10 },
      filter: {
        name,
        projectId: idProject,
      },
    },
    fetchPolicy: "network-only",
  });

  if (EDIT)
    columns.push({
      header: "Acción de eliminar",
      render: ({ id }) => (
        <Button
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "No podrás revertir esta acción.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí, eliminar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                handleMutationDelete(id);
              }
            });
          }}
          size="mini"
        >
          Eliminar
        </Button>
      ),
    });
  return (
    <>
      <Button
        size="tiny"
        compact
        icon="eye"
        content="Ver Beneficiarios"
        onClick={() => setOpen(true)}
      />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Beneficiarios</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label
                  content={`Cantidad (${
                    beneficiaries?.FilterFamilyMembers?.count || 0
                  })`}
                />
              </Form.Field>
              <Form.Input
                action={{
                  icon: "trash",
                  size: "mini",
                  content: "Limpiar",
                  onClick: () => setBeneficiary(""),
                }}
                value={beneficiary}
                size="mini"
                onChange={(e, { value }) => setBeneficiary(value)}
                placeholder="Búsqueda por nombre"
              />
              {EDIT && (
                <Popup
                  content={
                    <Form>
                      <Form.Group inline style={{ width: "500px" }}>
                        <Form.Dropdown
                          search
                          selection
                          loading={loading}
                          placeholder="Afiliado"
                          scrolling
                          style={{ width: "350px" }}
                          onResultSelect={(_, data) =>
                            setIdFamilyMenmber(data.result.id)
                          }
                          value={idFamilyMenmber}
                          onSearchChange={(_, { searchQuery }) => {
                            setName(searchQuery);
                          }}
                          onChange={(_, { value }) => {
                            setIdFamilyMenmber(value);
                          }}
                          options={data?.FilterFamilyMembers?.items?.map(
                            (data) => ({
                              ...data,
                              text: `${data.firstName}${
                                data?.secondName ? " " + data?.secondName : " "
                              }${" " + data.surname}${
                                data?.secondSurname
                                  ? " " + data?.secondSurname
                                  : " "
                              }`,
                            })
                          )}
                        />
                        <Form.Button
                          content="Agregar"
                          loading={loading}
                          disabled={idFamilyMenmber === "0" || loading}
                          onClick={handleMutation}
                        />
                      </Form.Group>
                    </Form>
                  }
                  on="click"
                  trigger={<Form.Button icon="add" size="tiny" compact />}
                />
              )}
            </Form.Group>
          </Form>
          <CustomTable
            currentPage={currentPage}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
            totalPages={beneficiaries?.FilterFamilyMembers?.totalPages}
            data={
              beneficiaries?.FilterFamilyMembers?.items
                .map((data) => ({
                  ...data,
                  name: `${data.firstName}${
                    data?.secondName ? " " + data?.secondName : " "
                  }${" " + data.surname}${
                    data?.secondSurname ? " " + data?.secondSurname : " "
                  }`,
                }))
                .filter((item) =>
                  item.name
                    .toLowerCase()
                    .includes(beneficiary.toLocaleLowerCase().trim())
                ) || []
            }
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

const ADD_FAMILY_MEMBERS = gql`
  mutation ($input: inputAddFamilyMenmber!) {
    addFamilyMenmber(input: $input) {
      firstName
      secondName
      surname
      secondSurname
      address
      id
      documents {
        id
        identification
      }
    }
  }
`;
const DELETE_FAMILY_MEMBERS = gql`
  mutation ($input: inputAddFamilyMenmber!) {
    deleteFamilyMenmber(input: $input) {
      firstName
      secondName
      surname
      secondSurname
      address
      id
      documents {
        id
        identification
      }
    }
  }
`;

const FAMILY_MEMBERS = gql`
  query ($page: PageInput!, $filter: FamilyMemberFilter) {
    FilterFamilyMembers(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        firstName
        secondName
        surname
        secondSurname
        address
        id
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
        key: id
        value: id
      }
    }
  }
`;
const PROYECT_FAMILY_MEMBERS = gql`
  query ($page: PageInput!, $filter: FamilyMemberFilter) {
    FilterFamilyMembers(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        firstName
        secondName
        surname
        secondSurname
        identification
        address
        id
        phone
        key: id
        value: id
      }
    }
  }
`;

export default Afiliado;
