import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputsFamily from "./InputsFamily";

const CreateFamilyMenmber = ({ idFamilyLeader, refetch }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [identification, setIdentification] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [typeIdentification, setTypeIdentification] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [exitDate, setExitDate] = useState("");
  const [expeditionDocumentDate, setExpeditionDocumentDate] = useState("");
  const [expirationDocumentDate, setExpirationDocumentDate] = useState("");
  const onCompleted = async () => {
    await refetch();
    setName("");
    setIdentification("");
    setBirthDate("");
    setPhone("");
    setMail("");
    setTypeIdentification("");
    setGender("");
    setMaritalStatus("");
    setExpeditionDocumentDate("");
    setExpirationDocumentDate("");
    setExitDate("");
    setLoading(false);

    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el usuario de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_FAMILY_MENMBER, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    if (new Date(birthDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de nacimiento es inválida.",
      });
    } else if (new Date(expeditionDocumentDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de expedición del documento es inválida.",
      });
    } else {
      setLoading(true);
      const input = {
        identification,
        idTypeIdentification: typeIdentification,
        name,
        idFamilyLeader,
        birthDate,
        idGender: gender,
        idMaritalStatus: maritalStatus,
      };

      if (phone.trim()) input.phone = phone;
      if (mail.trim()) input.mail = mail;
      if (exitDate.trim()) input.exitDate = exitDate;
      if (expirationDocumentDate.trim())
        input.expirationDocumentDate = expirationDocumentDate;
      if (expeditionDocumentDate.trim())
        input.expeditionDocumentDate = expeditionDocumentDate;

      mutation({ variables: { input } });
    }
  };
  const disabled = () => {
    if (!name.trim()) return true;
    if (!identification.trim()) return true;
    if (!birthDate.trim()) return true;
    if (!gender.trim()) return true;
    if (!maritalStatus.trim()) return true;
    if (!idFamilyLeader) return true;
    if (!typeIdentification.trim()) return true;
    if (loading) return true;
  };

  return (
    <>
      <Button
        style={{ backgroundColor: "#045A73", color: "white" }}
        onClick={() => setOpen(true)}
        content="Crear miembro familiar"
      />
      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear miembro familiar</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                value={name}
                onChange={(e, { value }) => setName(value)}
                label="Nombre Completo"
              />
              <Form.Input
                required
                value={identification}
                onChange={(e, { value }) => setIdentification(value)}
                label="Identificación"
              />
            </Form.Group>
            <InputsFamily
              maritalStatus={maritalStatus}
              setMaritalStatus={setMaritalStatus}
              gender={gender}
              setGender={setGender}
              typeIdentification={typeIdentification}
              setTypeIdentification={setTypeIdentification}
            />
            <Form.Group widths="equal">
              <Form.Input
                value={mail}
                onChange={(e, { value }) => setMail(value)}
                label="Correo (opcional)"
              />
              <Form.Input
                value={phone}
                onChange={(e, { value }) => setPhone(value)}
                label="Teléfono (opcional)"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                type="date"
                value={birthDate}
                onChange={(e, { value }) => setBirthDate(value)}
                label="Fecha de nacimiento"
              />
              <Form.Input
                type="date"
                value={expeditionDocumentDate}
                onChange={(e, { value }) => setExpeditionDocumentDate(value)}
                label="Fecha de expedición del documento (Opcional)"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                type="date"
                value={expirationDocumentDate}
                onChange={(e, { value }) => setExpirationDocumentDate(value)}
                label="Fecha de vencimiento (Opcional)"
              />
              <Form.Input
                type="date"
                value={exitDate}
                onChange={(e, { value }) => setExitDate(value)}
                label="Fecha de salida (Opcional)"
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={disabled()}
            onClick={handleMutation}
            loading={loading}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Guardar"
          />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_FAMILY_MENMBER = gql`
  mutation ($input: CreateFamilyMenmberInput!) {
    createFamilyMenmber(input: $input) {
      id
    }
  }
`;

export default CreateFamilyMenmber;
