import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateOption = ({ refetch, idQuestion }) => {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [negativeIndicator, setNegativeIndicator] = useState(false);
  const [optionQuestion, setOptionQuestion] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setResponse("");
    setNegativeIndicator(false);
    setQuestionText("");
    setOptionQuestion(false);
    setLoading(false);
    setOpen(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          response,
          idQuestion,
          negativeIndicator,
          optionQuestion,
          questionText,
        }
      }
    });
  }
  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      content={
        <div>
          <Form>
            <Form.TextArea
              value={response}
              onChange={(e, { value }) => setResponse(value)}
              style={{ minHeight: 60, maxHeight: 60 }}
              label="Respuesta" />
            <Form.Checkbox
              checked={negativeIndicator}
              onChange={(e, { checked }) => setNegativeIndicator(checked)}
              label="Indicador negativo" />
            <Form.Checkbox
              checked={optionQuestion}
              onChange={(e, { checked }) => setOptionQuestion(checked)}
              label="Opción pregunta" />
            {optionQuestion && (
              <Form.TextArea
                value={questionText}
                onChange={(e, { value }) => setQuestionText(value)}
                style={{ minHeight: 60, maxHeight: 60 }}
                label="Pregunta" />
            )}
            <Button
              loading={loading}
              disabled={!response.trim() || (optionQuestion && !questionText?.trim()) || loading}
              onClick={handleMutation}
              content="Crear opción" />
          </Form>
        </div>
      }
      on='click'
      positionFixed
      trigger={
        <Segment style={{ textAlign: 'center' }}>
          <Button onClick={() => setOpen(true)} circular icon="add" />
        </Segment>
      }
    />

  );
}

const CREATE_OPTION = gql`
  mutation($input: CreateFamilyPlanOptionInput!){
    createFamilyPlanOption(input: $input) {
      id
    }
  }
`;


export default CreateOption;