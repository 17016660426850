import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import DownloadReportList from "./DownloadList";
import DownloadReport from "./DownloadReport";
import ReportDetail from "./ReportDetail";
//import ReportDetail from "./ReportDetail";

const FamilyMembers = () => {
  return (
    <>
      <Content />
    </>
  );
};

const Content = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    filter: {
      workTeams: true,
    },
    page: { number: currentPage, limit: 10 },
  };
  const { loading, error, data } = useQuery(POLLSETR_LIST, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Primer nombre",
      accessor: "firstName",
    },
    {
      header: "Segundo nombre",
      accessor: "secondName",
    },
    {
      header: "Primer Apellido",
      accessor: "surname",
    },
    {
      header: "Segundo Apellido",
      accessor: "secondSurname",
    },
    {
      header: "Número de telefono",
      accessor: "phone",
    },
    {
      header: "Género",
      accessor: "gender.name",
    },
    {
      header: "Documento",
      accessor: "identification",
    },
    {
      header: "# Casa",
      render: (item) => {
        return item?.familyLeader?.houseNumber || "";
      },
    },
    {
      header: "Lider familiar",
      render: (item) => {
        return item?.familyLeader?.name || "";
      },
    },
    {
      header: "Nacionalidad",
      render: (item) => {
        return item?.nationality?.name || "";
      },
    },
    {
      header: "Dirección",
      render: (item) => {
        return item?.address || "";
      },
    },
    {
      header: "Género",
      render: (item) => {
        return item?.gender?.name || "";
      },
    },
    {
      header: "Fecha de nacimiento",
      accessor: "birthDate",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : ""),
    },
    {
      header: "Edad",
      accessor: "birthDate",
      render: (date) => (date ? moment().diff(moment(date), "years") : ""),
    },
    {
      header: "Tipo de documento de identidad",
      render: (item) => {
        return item?.typeIdentification?.name || "";
      },
    },
    {
      header: "Proyecto",
      render: ({ project }) => {
        return (
          <>
            {project.map((item) => (
              <Label key={item.id} content={item.name} />
            ))}
          </>
        );
      },
    },
    {
      header: "Encuestador",
      render: (item) => {
        return item?.familyLeader?.Encuestador?.name?.includes("undefined")
          ? ""
          : item?.familyLeader?.Encuestador?.name;
      },
    },
    {
      header: "Detalle",
      render: (info) => <ReportDetail id={info?.id} member={info?.name} />,
    },
  ];

  return (
    <>
      <Form>
        <Form.Group>
          <Label
            content={`Total beneficiarios creados (${
              data?.FilterFamilyMembers?.count || 0
            })`}
          />
          <DownloadReport />
        </Form.Group>
        <Form.Field>
          <DownloadReportList />
        </Form.Field>
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.FilterFamilyMembers?.totalPages || 0}
        data={data?.FilterFamilyMembers?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const POLLSETR_LIST = gql`
  query ($page: PageInput!, $filter: FamilyMemberFilter) {
    FilterFamilyMembers(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        firstName
        secondName
        surname
        secondSurname
        phone
        id
        identification
        residesInVillaCaracas
        exitDate
        familyLeader {
          id
          name
          documents {
            id
            active
            identification
            typeIdentification {
              id
              name
            }
          }
        }
        mail
        phone
        birthDate
        project {
          id
          name
        }
        gender {
          id
          name
        }
        maritalStatus {
          id
          name
        }
        typeIdentification {
          id
          name
        }
        regularization {
          id
          name
        }
        nationality {
          id
          name
        }
        socialSecurity {
          id
          name
        }
        educativeLevel {
          id
          name
        }
        occupation {
          id
          name
        }
        inabilities {
          id
          name
        }
        title
        familyLeader {
          id
          identification
          name
          houseNumber
          Encuestador {
            name
          }
        }
        lastGradeReached
        educativeEstablishment
        jobPerformed
        eps
        neighborhood
        address
        expeditionDocumentDate
        expirationDocumentDate
        responseQuestion {
          id
        }
      }
    }
  }
`;

export default FamilyMembers;
