import React, { useState } from "react";
import { Accordion, Button, Container, Form, Grid, Header, Icon, Input, Label, Modal, Segment } from "semantic-ui-react";
import MultiInputRecursive from "../../../../../Components/MultiInputRecursive";

const Preview = ({ survey }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal dimmer="inverted" trigger={<Form.Button style={{ backgroundColor: "#045A73", color: "white" }} onClick={() => setOpen(true)} icon="eye" fluid content="Vista previa" />} open={open} onClose={() => setOpen(false)}>
      <Modal.Header>Vista previa</Modal.Header>
      <Modal.Content scrolling>
        <Container text>
          <Segment>
            <Grid stackable columns='equal'>
              <Grid.Column>
                <Header as="h1">
                  {survey?.name}
                </Header>
                <Categories data={survey?.categories || []} />
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
}

const Categories = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  return (
    <>
      <Form size="large" >
        <Accordion>
          {data.map((category, key) => (
            <Segment key={key}>
              <Accordion.Title
                active={activeIndex === key}
                index={key}
                onClick={handleClick} >
                <Label attached={activeIndex === key ? "top left" : "top"}>
                  <Icon name='dropdown' />
                  {category?.name}
                </Label>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === key}>
                {category?.questions?.map((question, key) => (
                  <MultiInputRecursive
                    id={question?.id}
                    num={key}
                    type={question?.type?.name}
                    name={question?.name}
                    onChange={() => { }}
                    onChangeFile={() => { }}
                    key={key}
                    options={question?.options}
                    required={question?.required}
                    label={question?.enunciated}
                    control={Input}
                    placeholder={'Rellene la respuesta'} />
                ))}
              </Accordion.Content>
            </Segment>
          ))}
        </Accordion>
      </Form>
    </>
  );
}

export default Preview;