import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import ReportDetail from "./ReportDetail";

const FamilyReport = () => {
  return (
    <>
      <Content/>
    </>
  );
};

const Content = ({ filter }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    filter,
    page: { number: currentPage, limit: 10 },
  };
  const { loading, error, data } = useQuery(POLLSETR_LIST, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Nombre",
      accessor: "user.name",
    },
    {
      header: "Documento",
      accessor: "user.document",
    },
    {
      header: "Teléfono",
      accessor: "user.phone",
    },
    {
      header: "Familias asignadas",
      accessor: "count",
    },
    {
      header: "Detalle",
      render: (info) => (
        <ReportDetail
          id={info?.id}
          pollster={info?.user?.name}
        />
      ),
    },
  ];
  return (
    <>
      <Form>
        <Form.Field>
          <Label
            content={`Familias asignadas (${
              data?.reportFamily?.totalSurvey || 0
            })`}
          />
        </Form.Field>
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.reportFamily?.totalPages || 0}
        data={data?.reportFamily?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const POLLSETR_LIST = gql`
  query ($page: PageInput!) {
    reportFamily(page: $page) {
      page {
        number
        limit
      }
      count
      totalPages
      totalSurvey
      items {
        id
        user {
          id
          document
          phone
          name
        }
        count
      }
    }
  }
`;


export default FamilyReport ;
