import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import Selector from "./Selector";
import CreatePermit from "./Create";
import UpdatePermit from "./Update";
import DeletePermit from "./Delete";
const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
];

const Permissions = ({ permissions, reloadPermissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [action, setAction] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [module, setModule] = useState("");
  const filter = { status };
  if (action) filter.idAction = action;
  if (module) filter.idModule = module;
  if (userGroup) filter.idUserGroup = userGroup;
  const { data, loading, error, refetch } = useQuery(
    GET_PERMISSIONS_AND_FILTERS,
    {
      variables: { page: { number: currentPage, limit: 10 }, filter },
      fetchPolicy: "network-only",
    }
  );

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Grupo usuario",
      accessor: "userGroup.name",
    },
    {
      header: "Modulo",
      accessor: "module.name",
    },
    {
      header: "Acción",
      accessor: "action.name",
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (permit) => (
        <UpdatePermit
          data={data}
          permit={permit}
          error={error}
          reload={refetch}
          reloadPermissions={reloadPermissions}
          loading={!data ? true : false}
        />
      ),
    });

  if (DELETE && status !== "REMOVED")
    columns.push({
      header: "Acción de eliminar",
      render: (data) => (
        <DeletePermit
          reload={refetch}
          reloadPermissions={reloadPermissions}
          id={data?.id}
        />
      ),
    });

  return (
    <div>
      <h1>Permisos</h1>
      <Form>
        <Form.Group inline>
          {CREATE && (
            <CreatePermit
              data={data}
              loading={!data ? true : false}
              error={error}
              reloadPermissions={reloadPermissions}
              reload={refetch}
            />
          )}
          <Form.Field>
            <Label content={`Cantidad (${data?.permissionList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Selector
            label="Acción"
            loading={!data ? true : false}
            error={error}
            value={action}
            onChange={(e, { value }) => setAction(value)}
            data={data?.actionList?.items || []}
            placeholder="Búsqueda por acción"
          />
          <Selector
            label="Grupo de usuario"
            loading={!data ? true : false}
            error={error}
            value={userGroup}
            onChange={(e, { value }) => setUserGroup(value)}
            data={data?.userGroupList?.items || []}
            placeholder="Búsqueda por grupo usuario"
          />
          <Selector
            label="Módulo"
            loading={!data ? true : false}
            error={error}
            value={module}
            onChange={(e, { value }) => setModule(value)}
            data={data?.moduleList?.items || []}
            placeholder="Búsqueda por módulo"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.permissionList?.totalPages || 0}
        data={data?.permissionList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_PERMISSIONS_AND_FILTERS = gql`
  query getPermissionsAndFilters(
    $page: PageInput!
    $filter: PermissionFilter!
  ) {
    permissionList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        action {
          id
          name
        }
        module {
          id
          name
        }
        userGroup {
          id
          name
        }
      }
    }
    moduleList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      items {
        value: id
        text: name
        key: id
      }
    }
    actionList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      items {
        value: id
        text: name
        key: id
      }
    }
    userGroupList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default Permissions;
