import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";

function Ofertas({ offers, error, loading }) {
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataFiltered, setDataFiltered] = useState(offers || []);

  const searchProjec = (name) => {
    setDataFiltered(
      offers?.filter((header) =>
        header?.name?.toLowerCase().includes(name?.toLowerCase())
      )
    );
  };

  const clearFilter = () => {
    setProject("");
    setCurrentPage(1);
    setDataFiltered(offers);
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Programa",
      accessor: "programs",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Descripción",
      accessor: "description",
    },
  ];

  return (
    <>
      <Button
        size="tiny"
        compact
        icon="eye"
        content="Ver OFertas"
        onClick={() => setOpen(true)}
      />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Ofertas</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label content={`Cantidad (${dataFiltered?.length || 0})`} />
              </Form.Field>
              <Form.Input
                action={{
                  icon: "search",
                  size: "mini",
                  content: "Buscar",
                  onClick: () => searchProjec(project),
                }}
                value={project}
                size="mini"
                onChange={(e, { value }) => setProject(value)}
                placeholder="Búsqueda por nombre"
              />
              <Form.Button
                size="tiny"
                onClick={clearFilter}
                content="Vaciar filtro"
                compact
                icon="trash"
              />
            </Form.Group>
          </Form>
          <CustomTable
            currentPage={currentPage}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
            totalPages={dataFiltered.length > 0 ? dataFiltered.length : 0}
            data={dataFiltered.length > 0 ? dataFiltered : []}
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

export default Ofertas;
