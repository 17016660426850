import React from "react";
import { Card, Menu, Message, Pagination, Placeholder, Segment } from "semantic-ui-react";


const CustomCardList = ({
  error,
  loading,
  componentError,
  data = [],
  children,
  currentPage,
  onPageChange,
  totalPages
}) => {
  if (error) {
    if (componentError) return componentError;
    return (
      <div style={{ overflowX: 'overlay' }}>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );
  }

  if (loading) return (
    <Card.Group stackable>
      {[...Array(4)].map((_, key) => (
        <Card key={key}>
          <Segment raised>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Card>
      ))}
    </Card.Group>
  );

  return (
    <div>
      {children({ list: data })}
      <br />
      <br />
      <Menu pagination>
        <Pagination
          boundaryRange={0}
          defaultActivePage={currentPage}
          ellipsisItem={null}
          onPageChange={onPageChange}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={totalPages}
        />
      </Menu>
    </div>
  )

}

export default CustomCardList;