import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dimmer, Grid, Icon, Loader, Message, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import Categories from "./Categories";


const SurveyFamilyMenmber = ({ idAnswerHeader, openSurveyModal, setOpenSurveyModal }) => {
  const [idSurvey, setIdSurvey] = useState(null);
  const [include, setInclude] = useState(false);

  const onCompleted = ({ answerHeader }) => {
    setIdSurvey(answerHeader?.survey?.id);
    setInclude(true);
  }

  const handleCloseModal = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
      text: '¿Está seguro de que desea cerrar la encuesta? Tenga en cuenta que no se guardará ningún progreso.'
    }).then((result) => {
      if (result.isConfirmed) setOpenSurveyModal(false);
    });
  };

  const variables = { id: idAnswerHeader, includeIf: include };
  if (idSurvey) variables.idSurvey = idSurvey;
  const { loading, error, data } = useQuery(ANSWER_HEADER, {
    variables,
    onCompleted
  });

  if (loading) return (
    <div>
      <Dimmer active inverted>
        <Loader size='large'>Cargando</Loader>
      </Dimmer>
    </div>
  );

  if (error) return (
    <div style={{ margin: 30 }}>
      <Message>Lo sentimos, ha ocurrido un error.</Message>
    </div>
  );

  return (
    <Modal size="large" open={openSurveyModal}>
      <Modal.Header>
        {data?.answerHeader?.survey?.name}
        <Icon link onClick={handleCloseModal} color="red" style={{ float: 'right' }} name="delete" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid stackable columns='equal'>
          <Grid.Column>
            {data?.answerHeader?.answered
              ? <Message>Esta encabezado encuesta ya fue respondido anteriormente.</Message>
              : <Categories handleBack={setOpenSurveyModal} idAnswerHeader={idAnswerHeader} data={data?.answerHeader?.survey?.categories || []} />
            }
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

const ANSWER_HEADER = gql`
query($id: ID, $idSurvey: ID $includeIf: Boolean!) {
  answerHeader(id: $id) {
    id
    answered
    survey {
      id
      name
      status
      user {
        id
        name
      }
      categories {
        id
        name
        order
        questions(idSurvey: $idSurvey) @include(if: $includeIf){
          id
          name
          required
          enunciated
          order
          type {
            id
            name
          }
          options {
            id
            response
            order
            optionQuestion
            questionText
            questions{
              id
              name
              required
              enunciated
              order
              type {
                id
                name
              }
              options {
                id
                response
                order
                optionQuestion
                questionText
                questions{
                  id
                  name
                  required
                  enunciated
                  order
                  type {
                    id
                    name
                  }
                  options {
                    id
                    response
                    order
                    optionQuestion
                    questionText
                    questions{
                      id
                      name
                      required
                      enunciated
                      order
                      type {
                        id
                        name
                      }
                      options {
                        id
                        response
                        order
                        optionQuestion
                        questionText
                        questions{
                          id
                          name
                          required
                          enunciated
                          order
                          type {
                            id
                            name
                          }
                          options {
                            id
                            response
                            order
                            optionQuestion
                            questionText
                            questions{
                              id
                              name
                              required
                              enunciated
                              order
                              type {
                                id
                                name
                              }
                              options {
                                id
                                response
                                order
                                optionQuestion
                                questionText
                                questions{
                                  id
                                  name
                                  required
                                  enunciated
                                  order
                                  type {
                                    id
                                    name
                                  }
                                  options {
                                    id
                                    response
                                    order
                                    optionQuestion
                                    questionText
                                    questions{
                                      id
                                      name
                                      required
                                      enunciated
                                      order
                                      type {
                                        id
                                        name
                                      }
                                      options {
                                        id
                                        response
                                        order
                                        optionQuestion
                                        questionText
                                        questions{
                                          id
                                          name
                                          required
                                          enunciated
                                          order
                                          type {
                                            id
                                            name
                                          }
                                          options {
                                            id
                                            response
                                            order
                                            optionQuestion
                                            questionText
                                            questions{
                                              id
                                              name
                                              required
                                              enunciated
                                              order
                                              type {
                                                id
                                                name
                                              }
                                              options {
                                                id
                                                response
                                                order
                                                optionQuestion
                                                questionText
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default SurveyFamilyMenmber;