import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";

const Created = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    idWorkTeam: "",
  });
  const [, setSearch] = useState("");
  const { name, idWorkTeam } = inputs;
  const { data, loading, error } = useQuery(PROJECT, {
    variables: {},
    fetchPolicy: "network-only",
  });
  const onCompleted = async () => {
    await refetch();
    setInputs({
      name: "",
      project: 0,
    });
    setSearch("");
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el programa de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_PROGRAM, { onCompleted, onError });

  const capitalizar = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleMutation = () => {
    const input = {
      name,
      idWorkTeam,
    };
    if (name.trim()) input.name = capitalizar(name);
    if (idWorkTeam !== 0) input.idWorkTeam = idWorkTeam;
    mutation({ variables: { input } });
  };

  const disabled = () => {
    if (!name.trim()) return true;
    if (!idWorkTeam || idWorkTeam === 0) return true;
    if (loading) return true;
  };

  if (error) {
    return <Label content="Crear programa no disponible." />;
  }

  return (
    <>
      <Button size="tiny" compact icon="add" onClick={() => setOpen(true)} />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear programa</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Input
                required
                label="Nombre"
                value={name}
                onChange={(_, { value }) =>
                  setInputs({ ...inputs, name: value })
                }
              />
              <Form.Dropdown
                search
                selection
                clearable
                size="mini"
                required
                options={data?.workTeamListByUser || []}
                placeholder="Equipo de Trabajo"
                value={idWorkTeam}
                label="Equipo de Trabajo"
                onChange={(e, { value }) =>
                  setInputs({ ...inputs, idWorkTeam: value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Guardar"
            loading={loading}
            disabled={disabled()}
            onClick={handleMutation}
          />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const PROJECT = gql`
  query {
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

const CREATE_PROGRAM = gql`
  mutation ($input: inputCreateProgram!) {
    createProgram(input: $input) {
      id
    }
  }
`;

export default Created;
