import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Dropdown, Form, Icon, Input, List, Message, Modal, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";

const filterTypeText = {
  ID: {
    placeholder: "Buscar lider por identificación",
    message: "Digite la identificación para su busqueda."
  },
  NAME: {
    placeholder: "Buscar lider por nombre",
    message: "Digite un nombre para su busqueda."
  },
  HOUSE_NUMBER: {
    placeholder: "Buscar lider por número de casa",
    message: "Digite un número de casa para su busqueda."
  },
}

const TransferModal = ({ selected = [], setSelected, setAllSelected }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("ID");
  const [fakeSearch, setFakeSearch] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [item, setItem] = useState(null);
  const variables = {
    search: search.trim() ? true : false
  };
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data } = useQuery(FAMILY_LEADERS, {
    variables,
    fetchPolicy: "network-only"
  });
  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = () => {
    setLoadingSave(false);
    setItem(null);
    setSearch("");
    setFakeSearch("");
    setSelected([]);
    setAllSelected(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Transferencia exitosa.'
    }).then(() => setOpen(false));
  }

  const [mutation] = useMutation(TRANSFER_FAMILY_MEMBER, { onCompleted, onError });

  const handleDeleteSearch = () => {
    setSearch("");
    setFakeSearch("");
  }

  const handleFilterType = (e, { value }) => {
    setFilterType(value);
    setSearch("");
    setFakeSearch("");
  };

  const handleCleanItem = () => {
    setItem(null);
    setSearch("");
    setFakeSearch("");
  }
  const handleMutation = () => {
    setLoadingSave(true);
    mutation({
      variables: {
        input: {
          idLeader: item?.id,
          idsFamilyMember: selected?.map(v => v?.id)
        }
      }
    })
  }

  return (
    <Modal
      size="tiny"
      trigger={<Form.Button onClick={() => setOpen(true)} disabled={!selected?.length} compact size="tiny" content="Transferir" />}
      open={open}
      onClose={() => setOpen(false)}>
      <Modal.Content scrolling>
        <h3>Lista de miembros a transferir</h3>
        <Segment style={{ height: 150, overflow: 'auto' }}>
          <List relaxed divided>
            {selected.map(v => (
              <List.Item key={v?.id}>
                <List.Icon name='user' size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header>{v?.name}</List.Header>
                  <List.Description>
                    <Icon name="address card" />
                    {v?.identification}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
        <h3>A lider</h3>
        {item && (
          <List relaxed divided>
            <List.Item>
              <List.Content floated='right'>
                <Icon onClick={handleCleanItem} size="large" name="delete" color="red" link />
              </List.Content>
              <List.Icon name='user' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header as='a'>{item?.name}</List.Header>
                <List.Description as='a'>
                  <Icon name="home" />
                  {item?.houseNumber}
                </List.Description>
                <List.Description as='a'>
                  <Icon name="address card" />
                  {item?.identification}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        )}
        {!item && (
          <>
            <Dropdown
              value={filterType}
              onChange={handleFilterType}
              options={[
                { text: 'Identificación', value: 'ID', key: 1 },
                { text: 'Nombre', value: 'NAME', key: 2 },
                { text: '# Casa', value: 'HOUSE_NUMBER', key: 3 }
              ]
              } />
            <Input
              size='huge'
              fluid
              iconPosition={fakeSearch.trim() ? 'left' : null}
              icon={fakeSearch.trim() ? {
                name: 'delete',
                circular: true,
                link: true,
                onClick: () => handleDeleteSearch()
              } : null}
              placeholder={filterTypeText[filterType]?.placeholder}
              value={fakeSearch}
              action={{
                content: "Buscar",
                icon: "search",
                style: { backgroundColor: "#045A73", color: "white" },
                onClick: () => {
                  setSearch(fakeSearch);
                },
              }}
              onChange={(e, { value }) => setFakeSearch(value)}
            />
          </>
        )}
        {(data?.familyLeaders?.length === 0 && !item && !loading) && (
          <Message>No se encontró el lider.</Message>
        )}
        {loading && <h3>Buscando...</h3>}
        {error && <Message negative>Ha ocurrido un error.</Message>}
        {(data?.familyLeaders?.length > 0 && !item) && (
          <Segment style={{ height: 150, overflow: 'auto' }}>
            <h5>Seleccionar lider familiar</h5>
            <List relaxed divided>
              {data?.familyLeaders?.map(familyLeader => (
                <List.Item onClick={() => setItem(familyLeader)} key={familyLeader?.id}>
                  <List.Icon name='user' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header as='a'>{familyLeader?.name}</List.Header>
                    <List.Description as='a'>
                      <Icon name="home" />
                      {familyLeader?.houseNumber}
                    </List.Description>
                    <List.Description as='a'>
                      <Icon name="address card" />
                      {familyLeader?.identification}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loadingSave}
          onClick={handleMutation}
          disabled={!item || loadingSave}
          style={{ backgroundColor: "#045A73", color: "white" }}
          content="Transferir" />
        <Button onClick={() => setOpen(false)} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  );
}

const FAMILY_LEADERS = gql`
query($name: String, $identification: String, $houseNumber: String, $search: Boolean!) {
  familyLeaders(name: $name, identification: $identification, houseNumber: $houseNumber) @include(if: $search) {
    id
    identification
    name
    houseNumber
  }
}
`;

const TRANSFER_FAMILY_MEMBER = gql`
mutation($input: TransferFamilyMemberInput!) {
  transferFamilyMember(input: $input) {
    id
    firstName
    secondName
    surname
    secondSurname
    residesInVillaCaracas
    identification
    familyLeader {
      id
      name
      identification
      houseNumber
    }
  }
}
`;


export default TransferModal;