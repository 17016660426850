import { gql, useMutation } from "@apollo/client";
import React from "react";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";


const DuplicateSurvey = ({ idSurvey, refetch }) => {
  const onCompleted = async () => {
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: `Se ha duplicado la encuesta exitosamente.`,
    });
  }
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(DUPLICATE_SURVEY, { onCompleted, onError })
  const handleMutation = () => {
    Swal.fire({
      title: '¿Deseas realizar está acción?',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        mutation({
          variables: {
            idSurvey
          }
        })
      }
    })
  }
  return (
    <Button
      icon="copy"
      size="mini"
      compact
      onClick={handleMutation}
      style={{ backgroundColor: "#045A73", color: "white" }}
      content="Duplicar" />
  )
}

const DUPLICATE_SURVEY = gql`
  mutation($idSurvey: ID!) {
    duplicateSurvey(idSurvey: $idSurvey) {
      id
    }
  }
`;

export default DuplicateSurvey;