import React, { useState } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

const DocumentList = ({ data = [], label, compact }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal size="tiny" open={open} onClose={() => setOpen(false)} trigger={<Button compact={compact} size="tiny" onClick={() => setOpen(true)} icon="eye" content={label || "Ver lista"} />}>
      <Modal.Content>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Identificación</Table.HeaderCell>
              <Table.HeaderCell>Tipo de identifiación</Table.HeaderCell>
              <Table.HeaderCell>Activo</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((document, key) => (
              <Table.Row key={key}>
                <Table.Cell >
                  {document?.identification}
                </Table.Cell>
                <Table.Cell >
                  {document?.typeIdentification?.name}
                </Table.Cell>
                <Table.Cell >
                  {document?.active && <Icon color='green' name='checkmark' size='large' />}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
}

export default DocumentList;