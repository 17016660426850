import React, { useState } from "react";
import CustomTable from "../../../Components/CustomTable";
import { Button, Form, Label } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Created from "./Created";
import Updated from "./Updated";
import Offers from "./Offers";
import Swal from "sweetalert2";
import Project from "./Project";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "REMOVED", key: "REMOVED" },
];
const Program = ({ permissions, reloadPermissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [project, setProject] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const filter = { status, name: project };
  const { data, loading, error, refetch } = useQuery(GET_PROGRAM, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });
  const CREATE = permissions?.some((value) => value?.action.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );
  const onCompleted = async () => {
    await refetch();
    await reloadPermissions();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el programa de manera exitosa.",
    });
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(DELETE_PROJECT, { onCompleted, onError });
  const handleMutation = (id) => {
    mutation({ variables: { id } });
  };

  const searchProjec = (name) => {
    setDataFiltered(
      data?.programList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(name?.toLowerCase())
      )
    );
  };

  const clearFilter = () => {
    setProject("");
    setStatus("ACTIVE");
    setCurrentPage(1);
    setDataFiltered([]);
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Proyecto",
      render: (data) => (
        <Project
          idProgram={data.id}
          project={data.project}
          refetch={refetch}
          error={error}
          loading={loading}
          EDIT={EDIT}
        />
      ),
    },
    {
      header: "Ofertas",
      render: ({ offer, id }) => (
        <Offers
          idProgram={parseInt(id)}
          offers={offer || []}
          error={error}
          loading={loading}
          refetch={refetch}
          EDIT={EDIT}
        />
      ),
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (data) => <Updated refetch={refetch} dataProgram={data} />,
    });

  if (DELETE && status !== "REMOVED")
    columns.push({
      header: "Acción de eliminar",
      render: (data) => (
        <Button
          content="Eliminar"
          icon="trash"
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "No podrás revertir esta acción.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí, eliminar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                handleMutation(data?.id);
              }
            });
          }}
        />
      ),
    });

  return (
    <div>
      <h1>Programas</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <Created refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.programList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchProjec(project),
            }}
            value={project}
            size="mini"
            onChange={(e, { value }) => setProject(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.programList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.programList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_PROGRAM = gql`
  query ProgramList($page: PageInput!, $filter: ProgramFilter!) {
    programList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        workTeam {
          id
        }
        project {
          id
          name
          strategy
        }
        offer {
          id
          name
          description
          status
        }
        status
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation deleteProgram($id: ID!) {
    deleteProgram(id: $id)
  }
`;

export default Program;
