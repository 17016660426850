/* eslint-disable default-case */
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Swal from "sweetalert2";

const DownloadReport = ({ list, reportType }) => {
  const [loading, setLoading] = useState(false);

  const exportToExcel = () => {
    setLoading(true);
    const listData = [];
    let No = 1;
    switch (reportType) {
      case 1:
        list.forEach((item) => {
          item.offer.forEach((item2) => {
            listData.push({
              No,
              Programa: item.name,
              Oferta: item2.name,
              Descripción: item2.description,
            });
            No = No + 1;
          });
        });
        break;
      case 2:
        list.forEach((item) => {
          item.offer.forEach((item2) => {
            item2.offerHeader.forEach((item3) => {
              const { firstName, secondName, surname, secondSurname } =
                item3.familyMenmber;
              listData.push({
                No,
                Programa: item.name,
                Oferta: item2.name,
                Beneficiario: `${firstName} ${secondName ? secondName : ""} ${
                  surname ? surname : ""
                } ${secondSurname ? secondSurname : ""}`,
                Docuemnto: item3.familyMenmber.identification,
                telefono: item3.familyMenmber.phone,
              });
              No = No + 1;
            });
          });
        });
        break;
    }
    if (listData.length > 0) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(listData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `report-program-${new Date().getTime()}` + fileExtension
      );
    } else {
      Swal.fire({
        title: "No se Encontraron datos para generar el reporte",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Form.Field
      compact
      icon="file excel"
      loading={loading}
      disabled={loading}
      onClick={exportToExcel}
      size="mini"
      control={Button}
      content="Descargar"
    />
  );
};

export default DownloadReport;
