import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateAlly = ({ refetch }) => {
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [nit, setNit] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onCompleted = async () => {
    await refetch();
    setName("");
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado la acción de manera exitosa.",
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_ALLY, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(false);
    mutation({ variables: { name, nit, mail, phone, contactName } });
    clear();
  };

  const clear = () => {
    setContactName("");
    setMail("");
    setName("");
    setNit("");
    setPhone("");
  };

  useEffect(() => {
    clear();
  }, [open]);

  return (
    <>
      <Button size="tiny" compact icon="add" onClick={() => setOpen(true)} />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear aliado</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                label="Nombre"
                value={name}
                onChange={(e, { value }) => setName(value)}
              />
              <Form.Input
                label="Nombre contacto"
                value={contactName}
                onChange={(e, { value }) => setContactName(value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                value={nit}
                onChange={(e, { value }) => setNit(value)}
                label="Nit"
              />
              <Form.Input
                value={phone}
                onChange={(e, { value }) => setPhone(value)}
                label="Teléfono"
              />
            </Form.Group>

            <Form.Input
              value={mail}
              onChange={(e, { value }) => setMail(value)}
              label="Correo"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Guardar"
            loading={loading}
            disabled={!name.trim() || loading}
            onClick={handleMutation}
          />
          <Button onClick={() => setOpen(false)} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_ALLY = gql`
  mutation (
    $name: String!
    $phone: String!
    $nit: String!
    $mail: String!
    $contactName: String!
  ) {
    createAlly(
      name: $name
      phone: $phone
      nit: $nit
      mail: $mail
      contactName: $contactName
    ) {
      id
    }
  }
`;

export default CreateAlly;
