import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Accordion, Divider, Form, Icon, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import Question from "./Question";


const Category = ({ category, num, activeIndex, handleClick, refetch, idSurvey }) => {

  const [name, setName] = useState(category?.name || "");
  const [order, setOrder] = useState(category?.order || "1");
  const [loading, setLoading] = useState(false);
  const [activeIndexQuestion, setActiveIndexQuestion] = useState(-1);
  const handleClickQuestion = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndexQuestion === index ? -1 : index

    setActiveIndexQuestion(newIndex);
  }
  const onCompleted = async ({ updateCategory }) => {
    setName(updateCategory?.name);
    setOrder(updateCategory?.order);
    setLoading(false);
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_CATEGORY, { onError, onCompleted });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          id: category?.id,
          name,
          order: parseInt(order)
        }
      }
    })
  }
  return (
    <>
      <Segment>
        <Accordion.Title onClick={handleClick} index={num} active={activeIndex === num}>
          <Label style={{ backgroundColor: "#045A73", color: "white" }} attached='top'>
            <Icon name='dropdown' />
            {category?.name}
          </Label>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === num}>
          <Form widths="equal">
            <Form.Group>
              <Form.Input
                label="Nombre"
                value={name}
                onChange={(e, { value }) => setName(value)}
                fluid size='small'
                placeholder='Escriba un nombre para su categoría' />
              <Form.Input
                label="Orden"
                value={order}
                min="1"
                type="number"
                onChange={(e, { value }) => setOrder(value)}
                fluid size='small'
                placeholder='Escriba un orden para su categoría' />
            </Form.Group>
            <Form.Button
              loading={loading}
              disabled={!name.trim() || parseInt(order) < 0 || loading}
              onClick={handleMutation}
              style={{ backgroundColor: "#045A73", color: "white" }}
              content="Guardar" />
          </Form>
          <Divider horizontal>Preguntas</Divider>
          <Accordion>
            {category?.questions?.map((question, key) => (
              <Question
                handleClick={handleClickQuestion}
                activeIndex={activeIndexQuestion}
                num={key}
                refetch={refetch}
                key={key}
                question={question}
                idSurvey={idSurvey} />
            ))}
          </Accordion>
        </Accordion.Content>
      </Segment>
      <Divider />
    </>
  )
}

const UPDATE_CATEGORY = gql`
  mutation($input: UpdateCategoryInput!){
    updateCategory(input: $input) {
      id
      name
      order
      status
    }
  }
`;

export default Category;