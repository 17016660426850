import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import CustomCardList from "../../../Components/CustomCardList";
import { Card, Button, Label, Form } from "semantic-ui-react";
import { parseStatus } from "../../../Utils";
import CreateSurvey from "./CreateSurvey";
import DuplicateSurvey from "./DuplicateSurvey";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
];

const Surveys = ({ permissions, history }) => {
  const [status, setStatus] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(1);
  const goToSurvey = (id) => {
    history.push("/dashboard/Encuestas/" + id);
  };

  const { loading, error, data, refetch } = useQuery(SURVEYS, {
    variables: { page: { number: currentPage, limit: 10 }, filter: { status } },
    fetchPolicy: "network-only",
  });

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");
  const DUPLICATE = permissions?.some(
    (value) => value?.action?.name === "Duplicar"
  );
  // const RESPONSE = permissions?.some(value => value?.action?.name === "Responder");
  return (
    <>
      <h1>Encuestas</h1>
      <Form>
        <Form.Group inline>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
        </Form.Group>
      </Form>
      <CustomCardList
        loading={loading}
        error={error}
        currentPage={currentPage}
        totalPages={data?.surveyList?.totalPages || 0}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        data={data?.surveyList?.items || []}
      >
        {({ list }) => {
          if (!list.length) return CREATE && <CreateSurvey refetch={refetch} />;

          return (
            <Card.Group stackable>
              {list.map((survey, key) => (
                <Card key={key}>
                  <Card.Content>
                    <Label
                      style={{
                        backgroundColor:
                          survey?.status === "ACTIVE" ? "#045A73" : null,
                        color: survey?.status === "ACTIVE" ? "white" : null,
                      }}
                      attached="top left"
                    >
                      {parseStatus(survey?.status)}
                    </Label>
                    <Card.Header>{survey?.name}</Card.Header>
                    {DUPLICATE && (
                      <DuplicateSurvey
                        refetch={refetch}
                        idSurvey={survey?.id}
                      />
                    )}
                  </Card.Content>
                  {EDIT && (
                    <Card.Content extra>
                      <Button.Group fluid>
                        {
                          <Button
                            onClick={() => goToSurvey(survey?.id)}
                            content="Ver/Editar"
                          />
                        }
                      </Button.Group>
                    </Card.Content>
                  )}
                </Card>
              ))}
              {CREATE && <CreateSurvey refetch={refetch} />}
            </Card.Group>
          );
        }}
      </CustomCardList>
    </>
  );
};

const SURVEYS = gql`
  query surveys($page: PageInput!, $filter: SurveyFilter!) {
    surveyList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        status
      }
    }
  }
`;

export default Surveys;
