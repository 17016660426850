import React, { useState } from "react";
import CustomTable from "../../../Components/CustomTable";
import { Button, Form, Label } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import CreateProject from "./CreateProject";
import UpdateProject from "./UpdateProject";
import Afiliado from "./Afiliado";
import Swal from "sweetalert2";
import Programs from "./Programs";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "REMOVED", key: "REMOVED" },
];
const Project = ({ permissions, reloadPermissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [project, setProject] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const filter = { status, name: project };
  const { data, loading, error, refetch } = useQuery(GET_PROJECT, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });
  const CREATE = permissions?.some((value) => value?.action.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );

  const onCompleted = async () => {
    await refetch();
    await reloadPermissions();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el proyecto de manera exitosa.",
    });
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(DELETE_PROJECT, { onCompleted, onError });
  const handleMutation = (id) => {
    mutation({ variables: { id } });
  };

  const searchProjec = (name) => {
    setDataFiltered(
      data?.projectList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(name?.toLowerCase())
      )
    );
  };

  const clearFilter = () => {
    setProject("");
    setStatus("ACTIVE");
    setCurrentPage(1);
    setDataFiltered([]);
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Estrategia",
      accessor: "strategy",
    },
    {
      header: "Usuario Encargado",
      accessor: "userCharge.name",
    },
    {
      header: "Programas",
      render: (data) => (
        <Programs
          idProject={data.id}
          programs={data.programs}
          refetch={refetch}
          error={error}
          loading={loading}
          EDIT={EDIT}
        />
      ),
    },
    {
      header: "Beneficiarios",
      render: ({ id }) => (
        <Afiliado
          idProject={parseInt(id)}
          error={error}
          loading={loading}
          refetch={refetch}
          EDIT={EDIT}
        />
      ),
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (data) => <UpdateProject refetch={refetch} dataProject={data} />,
    });

  if (DELETE && status !== "REMOVED")
    columns.push({
      header: "Acción de eliminar",
      render: (data) => (
        <Button
          content="Eliminar"
          icon="trash"
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "No podrás revertir esta acción.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí, eliminar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                handleMutation(data?.id);
              }
            });
          }}
          size="mini"
        />
      ),
    });

  return (
    <div>
      <h1>Proyectos</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateProject refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.projectList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchProjec(project),
            }}
            value={project}
            size="mini"
            onChange={(e, { value }) => setProject(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.projectList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.projectList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_PROJECT = gql`
  query ProjectList($page: PageInput!, $filter: ProjectFilter!) {
    projectList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        strategy
        workTeam {
          id
        }
        userCharge {
          id
          name
        }
        programs {
          id
          name
        }
        status
      }
    }
  }
`;
const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;

export default Project;
