import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Dimmer,
  Loader,
  Message,
  Container,
  Segment,
  Form,
  Divider,
  Header,
  Icon,
  Accordion,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import Category from "./Category";
import CreateQuestion from "./Category/Question/CreateQuestion";
import Preview from "./Preview";

const Survey = () => {
  let { id } = useParams();
  let history = useHistory();
  const handleBack = () => {
    history.replace("/dashboard/Encuestas");
  };

  const { loading, error, data, refetch } = useQuery(SURVEY, {
    variables: { id },
  });
  if (loading)
    return (
      <div>
        <Dimmer active inverted>
          <Loader size="large">Cargando</Loader>
        </Dimmer>
      </div>
    );

  if (error)
    return (
      <div style={{ margin: 30 }}>
        <Header as="h1" style={{ margin: 20 }}>
          <Icon onClick={handleBack} link size="big" name="arrow left" />
          Encuesta
        </Header>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );

  return (
    <>
      {data?.survey ? (
        <Content
          handleBack={handleBack}
          refetch={refetch}
          data={data?.survey}
        />
      ) : (
        <>
          <Header as="h1" style={{ margin: 20 }}>
            <Icon onClick={handleBack} link size="big" name="arrow left" />
            Encuesta
          </Header>
          <Message>La encuesta a la que intentas acceder no existe.</Message>
        </>
      )}
    </>
  );
};

const Content = ({ data, refetch, handleBack }) => {
  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
  ];
  const [name, setName] = useState(data?.name || "");
  const [status, setStatus] = useState(data?.status  || "");
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_SURVEY, { onError, onCompleted });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        id: data?.id,
        name,
        status
      },
    });
  };
  return (
    <Container text>
      <Header as="h1" style={{ margin: 20 }}>
        <Icon onClick={handleBack} link size="big" name="arrow left" />
        Encuesta
      </Header>
      <Segment style={{ margin: 20 }}>
        <Form>
          <Form.Group inline widths="equal">
            <Preview survey={data} />
          </Form.Group>
        </Form>
        <Form>
          <Form.Input
            label="Nombre"
            value={name}
            onChange={(e, { value }) => setName(value)}
            fluid
            size="huge"
            placeholder="Escriba un nombre para su encuesta"
          />
          <Form.Dropdown
            required
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options} />
          <Form.Button
            loading={loading}
            disabled={!name.trim() || loading}
            onClick={handleMutation}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Guardar"
          />
        </Form>
        <Divider />
        <div style={{ marginTop: 20 }}>
          <CreateQuestion refetch={refetch} idSurvey={data?.id} />
          <Accordion>
            {data?.categories?.map((category, key) => (
              <Category
                num={key}
                key={key}
                idSurvey={data?.id}
                activeIndex={activeIndex}
                handleClick={handleClick}
                refetch={refetch}
                category={category}
              />
            ))}
          </Accordion>
        </div>
      </Segment>
    </Container>
  );
};

const UPDATE_SURVEY = gql`
  mutation ($id: ID!, $name: String, $status: Status) {
    updateSurvey(id: $id, name: $name, status: $status) {
      id
      name
    }
  }
`;

const SURVEY = gql`
  query survey($id: ID!) {
    survey(id: $id) {
      id
      name
      status
      user {
        id
        name
      }
      categories {
        id
        name
        order
        questions(idSurvey: $id) {
          id
          name
          required
          enunciated
          order
          type {
            id
            name
          }
          options {
            id
            response
            order
            optionQuestion
            questionText
            questions {
              id
              name
              required
              enunciated
              order
              type {
                id
                name
              }
              options {
                id
                response
                order
                optionQuestion
                questionText
                questions {
                  id
                  name
                  required
                  enunciated
                  order
                  type {
                    id
                    name
                  }
                  options {
                    id
                    response
                    order
                    optionQuestion
                    questionText
                    questions {
                      id
                      name
                      required
                      enunciated
                      order
                      type {
                        id
                        name
                      }
                      options {
                        id
                        response
                        order
                        optionQuestion
                        questionText
                        questions {
                          id
                          name
                          required
                          enunciated
                          order
                          type {
                            id
                            name
                          }
                          options {
                            id
                            response
                            order
                            optionQuestion
                            questionText
                            questions {
                              id
                              name
                              required
                              enunciated
                              order
                              type {
                                id
                                name
                              }
                              options {
                                id
                                response
                                order
                                optionQuestion
                                questionText
                                questions {
                                  id
                                  name
                                  required
                                  enunciated
                                  order
                                  type {
                                    id
                                    name
                                  }
                                  options {
                                    id
                                    response
                                    order
                                    optionQuestion
                                    questionText
                                    questions {
                                      id
                                      name
                                      required
                                      enunciated
                                      order
                                      type {
                                        id
                                        name
                                      }
                                      options {
                                        id
                                        response
                                        order
                                        optionQuestion
                                        questionText
                                        questions {
                                          id
                                          name
                                          required
                                          enunciated
                                          order
                                          type {
                                            id
                                            name
                                          }
                                          options {
                                            id
                                            response
                                            order
                                            optionQuestion
                                            questionText
                                            questions {
                                              id
                                              name
                                              required
                                              enunciated
                                              order
                                              type {
                                                id
                                                name
                                              }
                                              options {
                                                id
                                                response
                                                order
                                                optionQuestion
                                                questionText
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Survey;
