import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputTypePassword from "../../../Components/InputTypePassword";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../Utils/Constants";
import SelectUserGroup from "./SelectUserGroup";
const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';

const genderOptions = [
  { text: "Masculino", value: "Masculino", key: 1 },
  { text: "Femenino", value: "Femenino", key: 2 },
  { text: "Otro", value: "Otro", key: 3 }
];

const CreateUser = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [firstSurname, setFirstSurname] = useState("");
  const [secondSurname, setSecondSurname] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [idUserGroup, setIdUserGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setDocument("");
    setFirstName("");
    setSecondName("");
    setFirstSurname("");
    setSecondSurname("");
    setPassword("");
    setMail("");
    setPhone("");
    setGender("");
    setIdUserGroup("");
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha creado el usuario de manera exitosa.'
    }).then(() => setOpen(false))
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_USER, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      firstName,
      firstSurname,
      document,
      password,
      email: mail.trim(),
      idUserGroup,
    };
    if (secondName.trim()) input.secondName = secondName;
    if (secondSurname.trim()) input.secondSurname = secondSurname;
    if (phone.trim()) input.phone = phone;
    if (gender.trim()) input.gender = gender;
    mutation({ variables: { input } });
  }
  const validatePassword = () => {
    if (password.trim() && !PASSWORD_REGEX.test(password)) return passwordError;
    return null;
  }
  const disabled = () => {
    if (!firstName.trim()) return true;
    if (!firstSurname.trim()) return true;
    if (!document.trim()) return true;
    if (!PASSWORD_REGEX.test(password)) return true;
    if (!EMAIL_REGEX.test(mail)) return true;
    if (!idUserGroup.trim()) return true;
    if (!firstName.trim()) return true;
    if (loading) return true;
  }

  return (
    <>
      <Button size="tiny" compact icon="add" onClick={() => setOpen(true)} />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>
          Crear usuario
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Primer nombre"
                value={firstName}
                onChange={(e, { value }) => setFirstName(value)} />
              <Form.Input
                label="Segundo nombre"
                value={secondName}
                onChange={(e, { value }) => setSecondName(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Primer apellido"
                value={firstSurname}
                onChange={(e, { value }) => setFirstSurname(value)} />
              <Form.Input
                label="Segundo apellido"
                value={secondSurname}
                onChange={(e, { value }) => setSecondSurname(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Documento de identidad"
                value={document}
                onChange={(e, { value }) => setDocument(value)} />
              <Form.Input
                label="Teléfono"
                type="number"
                value={phone}
                onChange={(e, { value }) => setPhone(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                selection
                clearable
                options={genderOptions}
                label="Género"
                value={gender}
                onChange={(e, { value }) => setGender(value)} />
              <SelectUserGroup
                required
                label="Grupo usuario"
                value={idUserGroup}
                onChange={(e, { value }) => setIdUserGroup(value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputTypePassword
                required
                error={validatePassword()}
                label="Contraseña"
                value={password}
                type="password"
                onChange={(e, { value }) => setPassword(value)} />
              <Form.Input
                required
                label="Correo electrónico"
                error={mail && !EMAIL_REGEX.test(mail) ? "Ingresa dirección de correo electrónico válida." : null}
                value={mail}
                onChange={(e, { value }) => setMail(value)} />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Guardar"
            loading={loading}
            disabled={disabled()}
            onClick={handleMutation} />
          <Button
            content="Cancelar"
            onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
}



const CREATE_USER = gql`
  mutation($input: CreateUserInput!){
    createUser(input: $input) {
      id
    }
  }
`;

export default CreateUser;