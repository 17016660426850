import { useQuery, gql } from "@apollo/client";
import { Form } from "semantic-ui-react";

const InputsEducation = ({
  educativeLevel,
  setEducativeLevel,
  clearable,
  search = true,
  selection = true,
  required = true,
  disabled = false,
  title,
  setTitle,
  lastGradeReached,
  setLastGradeReached,
  NotStudyingReason,
  setNotStudyingReason,
  studying,
  setStudying,
  birthDate
}) => {
  const { loading, error, data } = useQuery(DATA);

  const calculateAge = (birthday) => {
    if (!birthday) return null;
    birthday = new Date(birthday);
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <>
      <Form.Dropdown
        scrolling
        placeholder="Nivel educativo"
        clearable={clearable}
        disabled={disabled}
        selection={selection}
        search={search}
        value={educativeLevel}
        onChange={(e, { value }) => {
          setLastGradeReached("");
          setEducativeLevel(value);
        }}
        loading={loading}
        error={error}
        options={data?.educativeLevel?.items || []}
        label="Nivel educativo actual"
      />
      {["56", "57", "58", "59"].includes(educativeLevel) && (
        <Form.Input
          type="text"
          value={title}
          readOnly={disabled}
          onChange={(e, { value }) => setTitle(value)}
          label="Título obtenido"
        />
      )}

      {["191", "192", "193", "55"].includes(educativeLevel) && (
        <Form.Input
          type="text"
          value={lastGradeReached}
          readOnly={disabled}
          onChange={(e, { value }) => setLastGradeReached(value)}
          label="Último grado alcanzado"
        />
      )}

      <Form.Group widths="equal">
        <Form.Checkbox
          checked={studying}
          disabled={disabled}
          onChange={(e, { checked }) => {
            setNotStudyingReason("")
            setStudying(checked);
          }}
          label="¿Estudia actualmente?"
        />
      </Form.Group>

      {calculateAge(birthDate) <= 20 && !studying && (
        <Form.Group widths="equal">
          <Form.Dropdown
            scrolling
            placeholder="Razón por la que no estudia"
            clearable={clearable}
            disabled={disabled}
            selection={selection}
            search={search}
            value={NotStudyingReason}
            onChange={(e, { value }) => {
              setNotStudyingReason(value);
            }}
            loading={loading}
            error={error}
            options={data?.NotStudyingReason?.items || []}
            label="Razón por la que no estudia"
          />
        </Form.Group>
      )}
    </>
  );
};

const DATA = gql`
  query {
    educativeLevel: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "13" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    NotStudyingReason: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "23" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default InputsEducation;
