import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label, Segment, Divider, Accordion, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import { parseTypeOptions } from "../../../../../../Utils";
import DeleteQuestion from "./DeleteQuestion";
import Options from "./Options";

const Question = ({ question, refetch, num, activeIndex, handleClick, idSurvey }) => {
  const type = question?.type?.name;
  const [enunciated, setEnunciated] = useState(question?.enunciated || "");
  const [name, setName] = useState(question?.name || "");
  const [required, setRequired] = useState(question?.required || false);
  const [order, setOrder] = useState(question?.order || "1");
  const [loading, setLoading] = useState(false);
  const onCompleted = async ({ updateQuestion }) => {
    setName(updateQuestion?.name);
    setEnunciated(updateQuestion?.enunciated);
    setRequired(updateQuestion?.required);
    setOrder(updateQuestion?.order);
    setLoading(false);
  }
  
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_QUESTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          id: question?.id,
          name,
          enunciated,
          order: parseInt(order),
          required
        }
      }
    });
  }

  const optionQuestionValidation = () => question?.type?.id === "24" || question?.type?.id === "25";

  return (
    <Segment style={{ marginTop: 20 }}>
      <Accordion.Title onClick={handleClick} index={num} active={activeIndex === num}>
        <Label style={{ backgroundColor: "#045A73", color: "white" }} attached='top'>
          <Icon name='dropdown' />
          {enunciated + " (" + parseTypeOptions(type) + ")"}
        </Label>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === num}>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              label="Nombre"
              value={name}
              onChange={(e, { value }) => setName(value)}
              placeholder="Agregar Nombre" />
            <Form.Input
              label="Enunciado"
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)}
              placeholder="Agregar Enunciado" />
            <Form.Input
              label="Orden"
              value={order}
              min="1"
              type="number"
              onChange={(e, { value }) => setOrder(value)}
              placeholder='Agregar orden' />
          </Form.Group>
          <Form.Group inline widths="equal">
            <Form.Button
              size="mini"
              fluid
              onClick={handleMutation}
              disabled={!enunciated.trim() || !name.trim() || parseInt(order) <= 0 || !order || loading}
              style={{ backgroundColor: "#045A73", color: "white" }}
              content="Guardar" />
            <DeleteQuestion
              refetch={refetch}
              idQuestion={question?.id} />
            <Form.Checkbox
              checked={required}
              onChange={(e, { checked }) => setRequired(checked)}
              toggle
              label="Obligatoria" />
          </Form.Group>

        </Form>
        {(type === "Opcion" || type === "Radio" || type === "Multiple-Opcion") && (
          <>
            <Divider horizontal>Opciones</Divider>
            <Options
              type={type}
              refetch={refetch}
              idQuestion={question?.id}
              options={question?.options}
              optionQuestionValidation={optionQuestionValidation}
              idSurvey={idSurvey}/>
          </>
        )}
      </Accordion.Content>
    </Segment >
  )
}

const UPDATE_QUESTION = gql`
  mutation($input: UpdateQuestionInput!){
    updateQuestion(input: $input) {
      id
      name
      order
      required
      enunciated
    }
  }
`;

export default Question;