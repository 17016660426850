import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import SurveyFamilyMenmber from "../../SearchFamilyMenmber/SurveyFamilyMenmber";

const ModalAnwserSurvey = ({ idSurvey, idFamilyMember }) => {
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  return (
    <Modal
      open={open}
      trigger={
        <Button
          style={{ backgroundColor: "#045A73", color: "white" }}
          size="mini"
          compact
          content="Responder"
          onClick={() => setOpen(true)}
        />
      }
      size="mini"
    >
      <Modal.Header>Elegir periodo</Modal.Header>
      <Content
        onClose={onCloseModal}
        idSurvey={idSurvey}
        idFamilyMember={idFamilyMember}
      />
    </Modal>
  );
};

const Content = ({ idSurvey, idFamilyMember, onClose }) => {
  const [idPeriod, setIdPeriod] = useState("");
  const [idAnswerHeader, setIdAnswerHeader] = useState("");
  const [idWorkTeam, setIdWorkTeam] = useState("");
  const [openSurveyModal, setOpenSurveyModal] = useState(false);
  const onCompleted = ({ createAnswerHeader }) => {
    setOpenSurveyModal(true);
    setIdAnswerHeader(createAnswerHeader?.id);
    setIdWorkTeam("");
  };
  const onBackView = () => {
    setOpenSurveyModal(false);
    setIdAnswerHeader("");
    onClose();
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const { loading, error, data } = useQuery(PERIOD_LIST, {
    variables: { idSurvey, idFamilyMenmber: idFamilyMember },
    fetchPolicy: "network-only",
  });
  const [mutation] = useMutation(CREATE_ANSWER_HEADER, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    Swal.fire({
      title: "¿Deseas realizar está acción?",
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        mutation({
          variables: {
            input: {
              idSurvey,
              idPeriod,
              idFamilyMenmber: idFamilyMember,
              idWorkTeam,
            },
          },
        });
      }
    });
  };

  return (
    <>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            value={idPeriod}
            loading={loading}
            error={error}
            fluid
            search
            label="Seleccionar periodo"
            placeholder="Eligir el periodo a responder"
            noResultsMessage="No hay periodos disponibles para responder"
            onChange={(e, { value }) => setIdPeriod(value)}
            options={data?.periodList?.items || []}
            selection
          />
          <Form.Dropdown
            search
            selection
            clearable
            size="mini"
            required
            options={data?.workTeamListByUser || []}
            placeholder="Equipo de Trabajo"
            value={idWorkTeam}
            label="Equipo de Trabajo"
            onChange={(e, { value }) => setIdWorkTeam(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleMutation}
          disabled={!idPeriod.trim()}
          content="Responder"
        />
        <Button onClick={onClose} content="Cancelar" />
      </Modal.Actions>
      <SurveyFamilyMenmber
        idAnswerHeader={idAnswerHeader}
        openSurveyModal={openSurveyModal}
        setOpenSurveyModal={onBackView}
      />
    </>
  );
};

const CREATE_ANSWER_HEADER = gql`
  mutation ($input: CreateAnswerHeaderInput!) {
    createAnswerHeader(input: $input) {
      id
    }
  }
`;

const PERIOD_LIST = gql`
  query ($idSurvey: ID, $idFamilyMenmber: ID) {
    periodList(
      page: { number: 1, limit: 100 }
      idFilter: $idSurvey
      type: SURVEY
      idFamilyMenmber: $idFamilyMenmber
    ) {
      count
      page {
        number
        limit
      }
      items {
        value: id
        text: name
        key: id
      }
      totalPages
    }
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

export default ModalAnwserSurvey;
