import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import CreateAlly from "./CreateAlly";
import UpdateAlly from "./UpdateAlly";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
];

const Allies = ({ permissions }) => {
  const [status, setStatus] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [fakeName, setFakeName] = useState("");

  const { data, loading, error, refetch } = useQuery(GET_ACCIONS, {
    variables: {
      page: { number: currentPage, limit: 10 },
      filter: { status, name },
    },
    fetchPolicy: "network-only",
  });

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action.name === "Editar");
  //const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Teléfono",
      accessor: "phone",
    },
    {
      header: "Nit",
      accessor: "nit",
    },
    {
      header: "Correo",
      accessor: "mail",
    },
    {
      header: "Nombre contacto",
      accessor: "contactName",
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (data) => <UpdateAlly refetch={refetch} ally={data} />,
    });
  /*
    if (DELETE && status !== "ELIMINADO") columns.push(
      {
        header: 'Acción de eliminar',
        render: data => ""
      }
    );
  */
  return (
    <div>
      <h1>Aliados</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateAlly refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.allyList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.allyList?.totalPages || 0}
        data={data?.allyList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_ACCIONS = gql`
  query ($page: PageInput!, $filter: AllyFilter!) {
    allyList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        nit
        mail
        phone
        status
        contactName
      }
    }
  }
`;

export default Allies;
