import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Grid, Loader, Message, Segment } from "semantic-ui-react";
import ColumnChart from "../../../Components/ColumnChart";
import { parseDataToJson } from "../../../Utils";


const GeneralReports = () => {
  const { loading, error, data } = useQuery(REPORTS, { fetchPolicy: "network-only" });

  if (loading) return (
    <div style={{ height: 300 }}>
      <Loader style={{ marginTop: 300 }} size="massive" active inline='centered' />
    </div>
  );

  if (error) return (
    <div style={{ height: 300 }}>
      <Message style={{ height: 300 }} error>
        Lo sentimos, ha ocurrido un error.
      </Message>
    </div>
  );

  return (
    <>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <ColumnChart
                title="Usuarios por género"
                data={parseDataToJson(data?.reportFamilyMenmberByGender?.data) || []} />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <ColumnChart
                title="Usuarios por estado civil"
                data={parseDataToJson(data?.reportFamilyMenmberByMaritalStatus?.data) || []} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <ColumnChart
                title="Usuarios por tipo identificación"
                data={parseDataToJson(data?.reportFamilyMenmberByTypeIdentification?.data) || []} />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <ColumnChart
                title="Usuarios por edad "
                data={parseDataToJson(data?.reportFamilyMenmberByAge?.data) || []} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

const REPORTS = gql`
  query{
    reportFamilyMenmberByGender {
      data {
        name
        y
        z
      }
    }
    reportFamilyMenmberByTypeIdentification {
      data {
        name
        y
        z
      }
    }
    reportFamilyMenmberByMaritalStatus {
      data {
        name
        y
        z
      }
    }
    reportFamilyMenmberByAge {
      data {
        name
        y
        z
      }
    }
  }
`;


export default GeneralReports;