import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import { gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
const Updated = ({ refetch, data }) => {
  const [name, setName] = useState(data.name || "");
  const [open, setOpen] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el equipo de trabajo de manera exitosa.",
    }).then(() => setOpen(false));
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(UPDATE_WORKTEAM, { onCompleted, onError });

  const capitalizar = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleMutation = () => {
    let newName = "";
    if (name.trim()) newName = capitalizar(name);
    mutation({ variables: { name: newName, id: data.id } });
  };

  return (
    <>
      <Popup
        open={open}
        content={
          <>
            <Form style={{ marginBottom: 10 }}>
              <Form.Input
                required
                label="Nombre"
                value={name}
                onChange={(_, { value }) => setName(value)}
              />
            </Form>
            <Button
              content="Guardar"
              disabled={!name.trim()}
              onClick={handleMutation}
            />
            <Button content="Cancelar" onClick={() => setOpen(false)} />
          </>
        }
        on="click"
        positionFixed
        trigger={
          <Button
            content="Actualizar"
            compact
            size="tiny"
            icon="edit"
            onClick={() => setOpen(true)}
          />
        }
      />
    </>
  );
};

const UPDATE_WORKTEAM = gql`
  mutation ($name: String!, $id: ID!) {
    updateWorkTeam(name: $name, id: $id) {
      id
    }
  }
`;

export default Updated;
