import React from "react";
import { Tab } from "semantic-ui-react";
import PeriodList from "./PeriodList";

const Periods = ({ permissions }) => {
  const panes = [
    {
      menuItem: "Encuestas",
      render: () => (
        <Tab.Pane>
          <PeriodList permissions={permissions} type={"SURVEY"} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Ofertas",
      render: () => (
        <Tab.Pane>
          <PeriodList permissions={permissions} type={"OFFER"} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Plan familiar",
      render: () => (
        <Tab.Pane>
          <PeriodList permissions={permissions} type={"FAMILYPLAN"} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <h1>Periodos</h1>
      <Tab panes={panes} />
    </div>
  );
};

export default Periods;
