import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";
import { EMAIL_REGEX } from "../../../Utils/Constants";

const UpdateMail = ({ user, refetch, setOpen }) => {
  const [email, setEmail] = useState(user?.email || "");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el correo de manera exitosa.'
    }).then(() => setOpen(false))
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_EMAIL, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const variables = {
      id: user?.id,
      email
    };

    mutation({ variables });
  }
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            required
            label="Correo"
            value={email}
            error={email && !EMAIL_REGEX.test(email) ? "Ingresa dirección de correo electrónico válida." : null}
            onChange={(e, { value }) => setEmail(value)} />
        </Form.Group>
      </Form>
      <div style={{ marginTop: 20 }}>
        <Button
          content="Guardar"
          style={{ backgroundColor: "#045A73", color: "white" }}
          loading={loading}
          disabled={!EMAIL_REGEX.test(email) || loading}
          onClick={handleMutation} />
        <Button
          content="Cancelar"
          onClick={() => setOpen(false)} />
      </div>
    </>
  );
}

const UPDATE_EMAIL = gql`
  mutation($id: ID! $email: EmailAddress!){
    updateEmail(id: $id email: $email) {
      id
    }
  }
`;


export default UpdateMail;