import { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";

const UpdateProject = ({ refetch, dataProject }) => {
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    ...dataProject,
    userCharge: dataProject?.userCharge?.id,
    idWorkTeam: dataProject?.workTeam?.id,
  });
  const [, setSearch] = useState(dataProject?.userCharge?.name);
  const { name, strategy, userCharge, idWorkTeam } = inputs;
  const { data, loading, error } = useQuery(USERS, {
    variables: {
      page: { number: 1, limit: 10000 },
      filter: { status: "ACTIVE" },
    },
    fetchPolicy: "network-only",
  });
  const onCompleted = async () => {
    await refetch();
    setInputs({
      name: "",
      strategy: "",
      userCharge: 1,
    });
    setSearch("");
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el proyecto de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_PROJECT, { onCompleted, onError });

  const handleMutation = () => {
    const input = {
      id: inputs.id,
      name,
      strategy,
      userCharge,
      idWorkTeam,
    };
    if (name.trim()) input.name = name;
    if (strategy.trim()) input.strategy = strategy;
    mutation({ variables: { input } });
  };

  const disabled = () => {
    if (!name.trim()) return true;
    if (!strategy.trim()) return true;
    if (!userCharge && userCharge !== 1) return true;
    if (loading) return true;
    if (!idWorkTeam && idWorkTeam !== 0) return true;
  };

  if (error) {
    return <Label content="Crear proyecto no disponible." />;
  }

  return (
    <>
      <Button
        size="tiny"
        compact
        icon="edit"
        content="Actualizar"
        onClick={() => setOpen(true)}
      />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Actualizar Proyecto</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Nombre"
                value={name}
                onChange={(_, { value }) =>
                  setInputs({ ...inputs, name: value })
                }
              />
              <Form.Dropdown
                search
                selection
                required
                clearable
                size="mini"
                options={data?.workTeamListByUser || []}
                placeholder="Equipo de Trabajo"
                value={idWorkTeam}
                label="Equipo de Trabajo"
                onChange={(e, { value }) =>
                  setInputs({ ...inputs, idWorkTeam: value })
                }
              />

              <Form.Dropdown
                search
                selection
                required
                clearable
                loading={loading}
                label="Usuario Encargado"
                placeholder="Usuario Encargado"
                onChange={(_, data) =>
                  setInputs({
                    ...inputs,
                    userCharge: data.value,
                  })
                }
                options={data?.userList?.items.map((data) => ({
                  key: data.id,
                  text: data.name,
                  value: data.id,
                }))}
                value={inputs.userCharge}
              />
            </Form.Group>
            <Form.TextArea
              required
              label="Estrategia"
              value={strategy}
              onChange={(_, { value }) =>
                setInputs({ ...inputs, strategy: value })
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Guardar"
            loading={loading}
            disabled={disabled()}
            onClick={handleMutation}
          />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation ($input: inputUpdateProject!) {
    updateProject(input: $input) {
      id
    }
  }
`;

export default UpdateProject;
