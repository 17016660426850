import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Card, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateSurvey = ({ refetch }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [idWorkTeam, setIdWorkTeam] = useState("");
  const { data } = useQuery(DATA);
  const onCompleted = async () => {
    await refetch();
    setName("");
    setLoading(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_SURVEY, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({ variables: { name, idWorkTeam } });
  };
  return (
    <>
      <Popup
        content={
          <Form>
            <Form.TextArea
              value={name}
              onChange={(e, { value }) => setName(value)}
              label="Nombre"
            />
            <Form.Dropdown
              search
              selection
              clearable
              size="mini"
              required
              options={data?.workTeamListByUser || []}
              placeholder="Equipo de Trabajo"
              value={idWorkTeam}
              label="Equipo de Trabajo"
              onChange={(e, { value }) => setIdWorkTeam(value)}
            />
            <Form.Button
              content="Guardar"
              onClick={handleMutation}
              loading={loading}
              disabled={!name.trim() || loading || !idWorkTeam.trim()}
              style={{ backgroundColor: "#045A73", color: "white" }}
            />
          </Form>
        }
        on="click"
        trigger={
          <Card>
            <Card.Content style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                icon="add"
                size="medium"
                style={{ backgroundColor: "#045A73", color: "white" }}
                circular
              />
            </Card.Content>
          </Card>
        }
      />
    </>
  );
};

const CREATE_SURVEY = gql`
  mutation ($name: String!, $idWorkTeam: ID!) {
    createSurvey(name: $name, idWorkTeam: $idWorkTeam) {
      id
    }
  }
`;

const DATA = gql`
  query {
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

export default CreateSurvey;
