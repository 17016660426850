import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
/* import Selector from "./Selector"; */
import Create from "./CreateAndUpdate";
import Update from "./CreateAndUpdate";
import Delete from "./Delete";
import ParameterValues from "./ParameterValues";

/* const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
  { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
]; */

const Parameters = ({ permissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState("ACTIVE");
  const filter = { status };

  const { data, loading, error, refetch } = useQuery(
    GET_PARAMETERS_AND_FILTERS,
    {
      variables: { page: { number: currentPage, limit: 10 }, filter },
      fetchPolicy: "network-only",
    }
  );

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Parámetro",
      accessor: "name",
    },
    {
      header: "Valores parámetros",
      render: (parameter) => (
        <ParameterValues reload={refetch} values={parameter.parameterValues} />
      ),
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (parameter) => (
        <Update
          data={data}
          parameter={parameter}
          error={error}
          reload={refetch}
          loading={!data ? true : false}
        />
      ),
    });

  if (DELETE && status !== "REMOVED")
    columns.push({
      header: "Acción de eliminar",
      render: (data) => <Delete reload={refetch} id={data?.id} />,
    });

  return (
    <div>
      <h1>Parámetros</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <Create reload={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.parameterList?.count || 0})`} />
          </Form.Field>
          {/* <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options} />
          <Form.Input
            action={{
              icon: 'search',
              size: 'mini',
              content: 'Buscar',
              onClick: () => setName(fakeName)
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre" /> */}
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.parameterList?.totalPages || 0}
        data={data?.parameterList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_PARAMETERS_AND_FILTERS = gql`
  query getParametersAndFilters($page: PageInput!, $filter: ParameterFilter!) {
    parameterList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        parameterValues {
          id
          name
          order
        }
      }
    }
  }
`;

export default Parameters;
