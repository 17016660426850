import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeleteParameter = ({ id, reload }) => {
  const [open, setOpen] = useState(false);
  
  const onCompleted = async () => {
    await reload();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el parámetro de manera exitosa.",
    }).then(() => setOpen(false));
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(DELETE_PARAMETER, { onCompleted, onError });
  const handleMutation = () => {
    mutation({ variables: { id } });
  };

  return (
    <div>
      <Button
        size="mini"
        content="Eliminar"
        icon="delete"
        onClick={() => setOpen(true)}
      />
      <Confirm
        size="tiny"
        confirmButton="Aceptar"
        cancelButton="Cancelar"
        open={open}
        content="¿Desea eliminar este parámetro?"
        onCancel={() => setOpen(false)}
        onConfirm={handleMutation}
      />
    </div>
  );
};

const DELETE_PARAMETER = gql`
  mutation deleteParameter($id: ID!) {
    deleteParameter(id: $id){
      id
    }
  }
`;

export default DeleteParameter;
