import { useQuery, gql } from "@apollo/client";
import { Form } from "semantic-ui-react";

const InputsHealth = ({
  socialSecurity,
  setSocialSecurity,
  clearable,
  search = true,
  selection = true,
  required = true,
  eps,
  setEps,
  inabilities,
  setInabilities,
  disabled = false,
  setHealthAffiliationDate,
  healthAffiliationDate,
  supportingElement,
  setSupportingElement,
  sisben,
  setSisben,
  sisbenCategory,
  setSisbenCategory,
  gender,
  birthDate,
  pregnant,
  setPregnant,
  attendsPrenatalControl,
  setAttendsPrenatalControl,
  PrenatalUnassistanceReason,
  setPrenatalUnassistanceReason,
}) => {
  const { loading, error, data } = useQuery(DATA);
  const calculateAge = (birthday) => {
    if (!birthday) return null;
    birthday = new Date(birthday);
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <>
      <Form.Input
        type="date"
        value={healthAffiliationDate}
        onChange={(e, { value }) => setHealthAffiliationDate(value)}
        label="Fecha de afiliación a servicios de salud"
      />
      <Form.Dropdown
        scrolling
        placeholder="Régimen de seguridad social"
        clearable={clearable}
        selection={selection}
        search={search}
        value={socialSecurity}
        disabled={disabled}
        required
        onChange={(e, { value }) => setSocialSecurity(value)}
        loading={loading}
        error={error}
        options={data?.socialSecurity?.items || []}
        label="Régimen de seguridad social"
      />
      {socialSecurity !== "77" && (
        <Form.Dropdown
          scrolling
          disabled={disabled}
          placeholder="Eps a la que pertenece"
          clearable={clearable}
          selection={selection}
          search={search}
          value={eps}
          onChange={(e, { value }) => setEps(value)}
          loading={loading}
          error={error}
          options={data?.eps?.items || []}
          label="Eps a la que pertenece"
        />
      )}
      <Form.Dropdown
        scrolling
        disabled={disabled}
        placeholder="Discapacidades"
        clearable={clearable}
        selection={selection}
        search={search}
        value={inabilities}
        onChange={(e, { value }) => {
          setSupportingElement("");
          setInabilities(value);
        }}
        loading={loading}
        error={error}
        options={data?.inabilities?.items || []}
        label="Discapacidades"
      />
      {inabilities !== "52" && inabilities !== "" && inabilities !== null && (
        <Form.Dropdown
          scrolling
          disabled={disabled}
          placeholder="Elemento de apoyo"
          clearable={clearable}
          selection={selection}
          search={search}
          value={supportingElement}
          onChange={(e, { value }) => setSupportingElement(value)}
          loading={loading}
          error={error}
          options={data?.supportingElements?.items || []}
          label="Elemento de apoyo"
        />
      )}
      <Form.Group>
        <Form.Checkbox
          checked={sisben}
          disabled={disabled}
          onChange={(e, { checked }) => {
            setSisbenCategory("");
            setSisben(checked);
          }}
          label="¿Está afiliado al sisbén?"
        />
      </Form.Group>
      {sisben !== "" && sisben !== false && (
        <Form.Group widths="equal">
          <Form.Dropdown
            scrolling
            disabled={disabled}
            placeholder="Categoría de sisbén"
            clearable={clearable}
            selection={selection}
            search={search}
            value={sisbenCategory}
            onChange={(e, { value }) => setSisbenCategory(value)}
            loading={loading}
            error={error}
            options={data?.sisbenCategories?.items || []}
            label="Categoría de sisbén"
          />
        </Form.Group>
      )}
      {gender !== "" &&
        birthDate !== "" &&
        gender === "3" &&
        calculateAge(birthDate) > 8 && (
          <>
            <Form.Group widths="equal">
              <Form.Checkbox
                checked={pregnant}
                disabled={disabled}
                onChange={(e, { checked }) => {
                  setAttendsPrenatalControl("");
                  setPregnant(checked);
                }}
                label="¿Está embarazada?"
              />
            </Form.Group>
            {pregnant && (
              <Form.Group widths="equal">
                <Form.Checkbox
                  checked={attendsPrenatalControl}
                  disabled={disabled}
                  onChange={(e, { checked }) => {
                    setAttendsPrenatalControl(checked);
                  }}
                  label="¿Asiste a control prenatal?"
                />
              </Form.Group>
            )}
            {pregnant && !attendsPrenatalControl && (
              <Form.Group widths="equal">
                <Form.Dropdown
                  scrolling
                  disabled={disabled}
                  placeholder="¿Por qué no asiste a control prenatal?"
                  clearable={clearable}
                  selection={selection}
                  search={search}
                  value={PrenatalUnassistanceReason}
                  onChange={(e, { value }) =>
                    setPrenatalUnassistanceReason(value)
                  }
                  loading={loading}
                  error={error}
                  options={data?.PrenatalUnassistanceReason?.items || []}
                  label="¿Por qué no asiste a control prenatal?"
                />
              </Form.Group>
            )}
          </>
        )}
    </>
  );
};

const DATA = gql`
  query {
    socialSecurity: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "17" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    inabilities: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "12" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    supportingElements: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "19" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    eps: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "18" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    sisbenCategories: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "21" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    PrenatalUnassistanceReason: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "22" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default InputsHealth;
