import { useQuery, gql } from "@apollo/client";
import React from "react";
import {
  Accordion,
  Button,
  Card,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import CreateFamilyMenmber from "./CreateFamilyMenmber";
import DocumentList from "./DocumentList";
import ObservationList from "./ObservationList";
import UpdateFamilyLeader from "./UpdateFamilyLeader";
import UpdateFamilyMenmber from "./UpdateFamilyMenmber";
//import ProjectModal from "./ProjectList";

const LeadersList = ({ search, filterType, goTo }) => {
  const variables = {};
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data, refetch } = useQuery(FAMILY_LEADERS, {
    variables,
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <Segment style={{ height: 300 }}>
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      </Segment>
    );
  if (error) return <Message>Lo sentimos, ha ocurrido un error.</Message>;

  const { familyLeaders } = data;

  if (!familyLeaders?.length)
    return (
      <div style={{ marginTop: 30 }}>
        <Message>No se ha encontrado ningún lider de familia.</Message>
      </div>
    );

  return familyLeaders?.map((familyLeader, key) => (
    <Segment key={key}>
      <Label
        style={{ backgroundColor: "#045A73", color: "white" }}
        attached="top"
      >
        Lider familiar
      </Label>
      <Table basic="very" fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Identificación</Table.HeaderCell>
            <Table.HeaderCell>Dirrección</Table.HeaderCell>
            <Table.HeaderCell>Encuestador(a) asignado</Table.HeaderCell>
            <Table.HeaderCell>Barrio</Table.HeaderCell>
            <Table.HeaderCell>Editar</Table.HeaderCell>
            <Table.HeaderCell>Lista de identificaciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>
                  {familyLeader?.name}
                  <Header.Subheader>Lider Familiar</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{familyLeader?.identification}</Table.Cell>
            <Table.Cell>{familyLeader?.houseNumber}</Table.Cell>
            <Table.Cell>
              {familyLeader?.Encuestador?.id
                ? familyLeader?.Encuestador.name
                : "No tiene un encuestador asignado"}
            </Table.Cell>
            <Table.Cell>
              {familyLeader?.etapa || "No tiene una etapa asignada"}
            </Table.Cell>
            <Table.Cell>
              <UpdateFamilyLeader user={familyLeader} />
            </Table.Cell>
            {/* <Table.Cell>
                {<ProjectModal idFamilyLeader={familyLeader?.id} />}
            </Table.Cell>
              */}
            <Table.Cell>
              {<DocumentList data={familyLeader?.documents} />}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider />
      <Accordion
        as={Form.Field}
        panels={[
          {
            key: "Members",
            title: "Miembros familiares",
            content: {
              content: (
                <FamilyMembers
                  goTo={goTo}
                  familyLeader={familyLeader}
                  refetch={refetch}
                />
              ),
            },
          },
        ]}
      />
    </Segment>
  ));
};

const FamilyMembers = ({ familyLeader, refetch, goTo }) => {
  return (
    <>
      <CreateFamilyMenmber
        idFamilyLeader={familyLeader?.id}
        refetch={refetch}
      />
      <br />
      <br />
      <Card.Group>
        {familyLeader?.familyMenmbers?.map((familyMenber, key) => (
          <Card key={key}>
            <Card.Content>
              <Card.Header>
                {familyMenber?.firstName} {familyMenber?.secondName}{" "}
                {familyMenber?.surname} {familyMenber?.secondSurname}
              </Card.Header>
              <Card.Meta>Miembro familiar</Card.Meta>
              <Card.Meta>
                <Popup
                  content="Identificación"
                  trigger={<Icon name="address card" />}
                />{" "}
                {familyMenber?.identification}
              </Card.Meta>
              <Card.Meta>
                <Popup
                  content="Fecha de nacimiento"
                  trigger={<Icon name="birthday cake" />}
                />{" "}
                {familyMenber?.birthDate}
              </Card.Meta>
              <Card.Meta>
                <Popup content="Teléfono" trigger={<Icon name="phone" />} />{" "}
                {familyMenber?.phone || " - - - - - - - - -"}
              </Card.Meta>
              <Card.Meta>
                <Popup
                  content="Correo electrónico"
                  trigger={<Icon name="mail" />}
                />{" "}
                {familyMenber?.mail || " - - - - - - - - -"}
              </Card.Meta>
              <Card.Meta>
                <Popup
                  content="Tipo de identificación"
                  trigger={<Icon name="address book" />}
                />{" "}
                {familyMenber?.typeIdentification?.name}
              </Card.Meta>
              <Card.Meta>
                <Popup content="Estado civil" trigger={<Icon name="user" />} />
                {familyMenber?.maritalStatus?.name}
              </Card.Meta>
              <Card.Description>
                <DocumentList
                  compact
                  label="Lista de identificaciones"
                  data={familyMenber?.documents}
                />
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <UpdateFamilyMenmber user={familyMenber} />
              <br />
              <ObservationList
                fluid
                content="Lista de observaciones"
                id={familyMenber?.id}
                refetch={refetch}
                data={familyMenber?.observations}
              />
              <br />
              <Button
                disabled={
                  familyMenber?.exitDate &&
                  new Date(familyMenber?.exitDate) <= new Date()
                }
                onClick={() => goTo(familyMenber?.id)}
                size="tiny"
                fluid
                content="Responder"
                style={{ backgroundColor: "#045A73", color: "white" }}
              />
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </>
  );
};

const FAMILY_LEADERS = gql`
  query ($identification: String, $name: String, $houseNumber: String) {
    familyLeaders(
      identification: $identification
      name: $name
      houseNumber: $houseNumber
    ) {
      id
      name
      identification
      Encuestador {
        id
        name
      }
      etapa
      documents {
        id
        active
        identification
        typeIdentification {
          id
          name
        }
      }
      familyMenmbers {
        id
        firstName
        secondName
        surname
        secondSurname
        exitDate
        observations {
          id
          description
          startDate
          user {
            id
            name
          }
        }
        birthDate
        phone
        mail
        identification
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
        gender {
          id
          name
        }
        typeIdentification {
          id
          name
        }
        maritalStatus {
          id
          name
        }
        expeditionDocumentDate
        expirationDocumentDate
      }
      houseNumber
    }
  }
`;

export default LeadersList;
