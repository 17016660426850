import React from "react";
import { Tab } from "semantic-ui-react";
import Survey from "./Survey";
import FamilyPlan from "./FamilyPlan";

const History = ({ permissions }) => {
  const panes = [
    {
      menuItem: "Encuestas",
      render: () => (
        <Tab.Pane>
          <Survey permissions={permissions} type={"SURVEY"} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Plan familiar",
      render: () => (
        <Tab.Pane>
          <FamilyPlan permissions={permissions} type={"FAMILYPLAN"} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <h1>Historial respuestas</h1>
      <Tab panes={panes} />
    </div>
  );
};

export default History;
