import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Accordion, Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import UpdateOption from "./UpdateOption";

const Options = ({
  idQuestion,
  options,
  refetch,
  type,
  optionQuestionValidation,
  idSurvey
}) => {
  const [response, setResponse] = useState("");
  const [optionQuestion, setOptionQuestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  const onCompleted = async () => {
    await refetch();
    setResponse("");
    setOptionQuestion(false);
    setQuestion("");
    setLoading(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      idQuestion,
      response,
      optionQuestion,
    };

    if (optionQuestion) input.questionText = question;

    mutation({ variables: { input } });
  };
  const disabled = () => {
    if (!response.trim()) return true;
    if (optionQuestion) {
      if (!question.trim()) return true;
    }
    if (loading) return true;
  };

  return (
    <>
      <Form>
        <Accordion>
          {options.map((option, key) => (
            <UpdateOption
              type={type}
              refetch={refetch}
              key={key}
              activeIndex={activeIndex}
              handleClick={handleClick}
              option={option}
              num={key}
              optionQuestionValidation={optionQuestionValidation}
              idSurvey={idSurvey}
            />
          ))}
        </Accordion>
      </Form>
      <br />
      <Popup
        content={
          <>
            <Form>
              <Form.TextArea
                value={response}
                onChange={(e, { value }) => setResponse(value)}
                label="Respuesta"
              />
              {type === "Radio" && (
                <>
                  <Form.Checkbox
                    checked={optionQuestion}
                    onChange={(e, { checked }) => setOptionQuestion(checked)}
                    toggle
                    label="Opción pregunta"
                  />
                  {optionQuestion && (
                    <Form.TextArea
                      value={question}
                      onChange={(e, { value }) => setQuestion(value)}
                      label="Pregunta"
                    />
                  )}
                </>
              )}
              <Form.Button
                content="Guardar"
                onClick={handleMutation}
                loading={loading}
                disabled={disabled()}
                style={{ backgroundColor: "#045A73", color: "white" }}
              />
            </Form>
          </>
        }
        on="click"
        pinned
        trigger={
          <Button
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Crear opción"
          />
        }
      />
    </>
  );
};

const CREATE_OPTION = gql`
  mutation ($input: CreateOptionInput!) {
    createOption(input: $input) {
      id
    }
  }
`;

export default Options;
