import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateAction = ({ refetch, action }) => {
  const [name, setName] = useState(action?.name || "");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el acción de manera exitosa.'
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_ACTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: { id: action?.id, name }
    });
  }
  return (
    <Popup
      content={
        <Form>
          <Form.Input
            value={name}
            onChange={(e, { value }) => setName(value)}
            label="Nombre" />
          <Form.Button
            onClick={handleMutation}
            loading={loading}
            disabled={!name.trim() || loading}
            content="Guardar" />
        </Form>
      }
      on='click'
      positionFixed
      trigger={<Button compact size="tiny" icon="edit" />}
    />
  );
}

const UPDATE_ACTION = gql`
mutation($id: ID!, $name: String!){
  updateAction(id: $id, name: $name) {
    id
  }
}
`;

export default UpdateAction;