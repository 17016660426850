import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Accordion, Card, Container, Segment } from "semantic-ui-react";
import CustomCardList from "../../../Components/CustomCardList";
import FamilyPlanCategories from "./Categories";
import CreateFamilyPlan from "./CreateFamilyPlan";
const FamilyPlan = ({ permissions }) => {
  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data, refetch } = useQuery(FAMILY_PLAN_LIST, {
    variables: {
      page: {
        number: currentPage,
        limit: 8,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) return "Cargando...";
  if (error) return "Error...";

  const { familyPlanList } = data;

  return (
    <Container text>
      <h1>Encuestas de plan familiar</h1>
      <CustomCardList
        loading={loading}
        error={error}
        currentPage={currentPage}
        totalPages={familyPlanList?.totalPages || 0}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        data={familyPlanList?.items || []}
      >
        {({ list }) => {
          return (
            <>
              {list.map((familyPlan) => (
                <Segment secondary key={familyPlan?.id}>
                  <Accordion
                    panels={[
                      {
                        key: familyPlan?.id,
                        title: familyPlan?.name,
                        content: {
                          content: (
                            <Card.Content>
                              <FamilyPlanCategories
                                actionCreate={CREATE}
                                actionUpdate={EDIT}
                                actionDelete={DELETE}
                                idFamilyPlan={familyPlan?.id}
                                name={familyPlan?.name}
                                refetch={refetch}
                                categories={familyPlan?.categories}
                              />
                            </Card.Content>
                          ),
                        },
                      },
                    ]}
                  />
                </Segment>
              ))}
              {CREATE && <CreateFamilyPlan refetch={refetch} />}
            </>
          );
        }}
      </CustomCardList>
    </Container>
  );
};

const FAMILY_PLAN_LIST = gql`
  query ($page: PageInput!) {
    familyPlanList(page: $page, filter: { status: ACTIVE }) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
        categories {
          id
          name
          order
        }
      }
    }
  }
`;

export default FamilyPlan;
