import { useQuery, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
//import Swal from "sweetalert2";
import CreateFamilyMenmber from "./Create";
import UpdateFamilyMenmber from "./Update";
import DeleteFamilyMember from "./Delete";
import ViewFamilyMember from "./View";
const Members = ({ permissions, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [dataFiltered, setDataFiltered] = useState([]);
  const [fakeName, setFakeName] = useState("");
  const [fakeDocument, setFakeDocument] = useState("");
  const [fakeHouseNumber, setFakeHouseNumber] = useState("");

  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");

  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");

  const [filter, setFilter] = useState({});

  const { data, loading, error, refetch } = useQuery(HISTORY_SURVEY, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setCurrentPage(1);
    refetch();
    //eslint-disable-next-line
  }, [filter]);

  const columns = [
    {
      header: "Tipo de Documento",
      accessor: "typeIdentification.name",
    },
    {
      header: "Documento",
      accessor: "identification",
    },
    {
      header: "Primer nombre",
      accessor: "firstName",
    },
    {
      header: "Segundo nombre",
      accessor: "secondName",
    },
    {
      header: "Primer Apellido",
      accessor: "surname",
    },
    {
      header: "Segundo Apellido",
      accessor: "secondSurname",
    },
    {
      header: "Género",
      accessor: "gender.name",
    },

    {
      header: "# Casa",
      accessor: "familyLeader.houseNumber",
    },
    {
      header: "Edad",
      accessor: "birthDate",
      render: (date) => {
        const calculateAge = (birthday) => {
          if (!birthday) return null;
          birthday = new Date(birthday);
          let ageDifMs = Date.now() - birthday.getTime();
          let ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        return calculateAge(date);
      },
    },
    {
      header: "Lider familiar",
      accessor: "familyLeader.name",
    },
    {
      header: "Ofertas",
      accessor: "offers.length",
    },
    {
      header: "Tipos de Ofertas",
      render: ({ offers }) => {
        let types = offers.map((item) => item.offer.offerType.name);
        types = types.filter((elemento, index) => {
          return types.indexOf(elemento) === index;
        });
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              textWrap: "wrap",
            }}
          >
            {types.map((item, index) => (
              <Label key={index} style={{ marginTop: 5 }}>
                {item}
              </Label>
            ))}
          </div>
        );
      },
    },
    {
      header: "Ver",
      render: (element) => (
        <ViewFamilyMember user={element} refetch={refetch} />
      ),
    },
  ];

  if (EDIT) {
    columns.push({
      header: "Editar",
      render: (element) => (
        <UpdateFamilyMenmber user={element} refetch={refetch} />
      ),
    });
  }

  if (DELETE) {
    columns.push({
      header: "Eliminar",
      render: (element) => (
        <DeleteFamilyMember
          member={element}
          refetch={refetch}
          setFilter={setFilter}
        />
      ),
    });
  }
  const clearFilter = () => {
    setFakeDocument("");
    setFakeName("");
    setFakeHouseNumber("");
    setFilter({});
  };

  const searchName = (fakeName) => {
    setFilter({ ...filter, name: fakeName });
  };
  const searchDocument = (fakeName) => {
    setFilter({ ...filter, identification: fakeName });
  };
  const searchHouseNumber = (fakeName) => {
    setFilter({ ...filter, houseNumber: fakeName });
  };

  return (
    <div>
      <h1>Beneficiarios</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateFamilyMenmber refetch={refetch} />}
          <Form.Field>
            <Label
              content={`Cantidad (${
                dataFiltered.length > 0
                  ? dataFiltered.length
                  : data?.FilterFamilyMembers?.count || 0
              })`}
            />
          </Form.Field>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchDocument(fakeDocument),
            }}
            value={fakeDocument}
            size="mini"
            onChange={(e, { value }) => setFakeDocument(value)}
            placeholder="Búsqueda por documento"
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchHouseNumber(fakeHouseNumber),
            }}
            value={fakeHouseNumber}
            size="mini"
            onChange={(e, { value }) => setFakeHouseNumber(value)}
            placeholder="Búsqueda por número de casa"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.FilterFamilyMembers?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.FilterFamilyMembers?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};
const HISTORY_SURVEY = gql`
  query ($page: PageInput!, $filter: FamilyMemberFilter!) {
    FilterFamilyMembers(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        firstName
        secondName
        surname
        secondSurname
        id
        identification
        residesInVillaCaracas
        exitDate
        isFamilyLeader

        offers {
          id
          offer {
            id
            name
            offerType {
              id
              name
            }
          }
        }
        leaderRelation {
          id
          name
        }
        healthAffiliationDate
        supportingElement {
          id
          name
        }
        sisben
        sisbenCategory {
          id
          name
        }
        pregnant
        attendsPrenatalControl
        PrenatalUnassistanceReason {
          id
          name
        }
        studying
        NotStudyingReason {
          id
          name
        }
        contractType {
          id
          name
        }
        independentType
        undertakes
        undertakeName
        artOrWork
        artOrWorkType
        familyLeader {
          id
          name
          documents {
            id
            active
            identification
            typeIdentification {
              id
              name
            }
          }
        }
        mail
        phone
        birthDate
        gender {
          id
          name
        }
        maritalStatus {
          id
          name
        }
        typeIdentification {
          id
          name
        }
        regularization {
          id
          name
        }
        nationality {
          id
          name
        }
        socialSecurity {
          id
          name
        }
        educativeLevel {
          id
          name
        }
        occupation {
          id
          name
        }
        inabilities {
          id
          name
        }
        title
        familyLeader {
          id
          identification
          name
          houseNumber
        }
        lastGradeReached
        educativeEstablishment
        jobPerformed
        eps
        neighborhood
        address
        expeditionDocumentDate
        expirationDocumentDate
        responseQuestion {
          id
        }
      }
    }
  }
`;

export default Members;
