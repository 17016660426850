import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Form } from "semantic-ui-react";
import { getData } from "../../../Utils";

const SelectUserGroup = ({
  onChange,
  value,
  required,
  label,
  placeholder,
  clearable,
  selection = true,
}) => {
  const { loading, error, data } = useQuery(USERS_GROUP, { fetchPolicy: "network-only" });
  const me = getData("meBid");
  const checkSuperAdmin = me?.userGroup?.id === "1";
  let userGroupData = [];
  if (Array.isArray(data?.userGroupList?.items)) {
    if (!checkSuperAdmin) userGroupData = data?.userGroupList?.items?.filter(userGroup => userGroup?.text !== "Super admin");
    else
      userGroupData = data?.userGroupList?.items;
  }
  return (
    <Form.Dropdown
      required={required}
      label={label}
      placeholder={placeholder}
      clearable={clearable}
      scrolling
      selection={selection}
      loading={loading}
      error={error}
      value={value}
      onChange={onChange}
      options={userGroupData} />
  );
}

const USERS_GROUP = gql`
  query {
    userGroupList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default SelectUserGroup;