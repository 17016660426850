import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Card, Message } from "semantic-ui-react";
import CustomCardList from "../../../../../Components/CustomCardList";
import ModalAnwserFamilyPlan from "./ModalAnwserFamilyPlan";
import History from "./history/HistoryFamilyPlan";

const FamilyPlanContent = ({ idFamilyMember }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data } = useQuery(FAMILY_PLAN, {
    fetchPolicy: "network-only",
    variables: {
      page: { number: currentPage, limit: 10 },
      filter: { status: "ACTIVE" },
    },
  });

  return (
    <CustomCardList
      loading={loading}
      error={error}
      currentPage={currentPage}
      totalPages={data?.familyPlanList?.totalPages || 0}
      onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
      data={data?.familyPlanList?.items || []}
    >
      {({ list }) => {
        if (!list.length)
          return <Message>No hay planes familiares disponibles.</Message>;
        return (
          <Card.Group stackable>
            {list.map((familyPlan, key) => (
              <Card key={key}>
                <Card.Content>
                  <Card.Header>{familyPlan?.name}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <div className="ui two buttons">
                    <ModalAnwserFamilyPlan
                      idFamilyMember={idFamilyMember}
                      idFamilyPlan={familyPlan?.id}
                    />
                    <History
                      idFamilyPlan={familyPlan?.id}
                      idFamilyMenmber={idFamilyMember}
                    />
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        );
      }}
    </CustomCardList>
  );
};

const FAMILY_PLAN = gql`
  query ($page: PageInput!, $filter: FamilyPlanFilter!) {
    familyPlanList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
      }
    }
  }
`;

export default FamilyPlanContent;
