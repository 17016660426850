import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { parseTypeOptions } from "../../../../../../Utils/index";
import CreateCategory from "../../CreateCategory";

const CreateQuestion = ({ idSurvey, refetch }) => {
  const [open, setOpen] = useState(false);
  const [enunciated, setEnunciated] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [required, setRequired] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = async () => {
    await refetch();
    Swal.fire('La pregunta se ha creado exitosamente.', '', 'success')
      .then(() => {
        setEnunciated("");
        setName("");
        setType("");
        setCategory("");
        setRequired(false);
        setLoadingSave(false);
        setOpen(false);
      });
  }

  const { loading, error, data, refetch: reload } = useQuery(DATA);
  const [mutation] = useMutation(CREATE_QUESTION, { onCompleted, onError });


  let options = data?.typeOptions?.items || [];
  if (Array.isArray(options)) {
    options = options?.map(value => ({ ...value, text: parseTypeOptions(value?.text) || value?.text }));
  }

  const handleMutation = () => {
    setLoadingSave(true);
    const input = {
      name,
      idSurvey,
      idCategory: category,
      enunciated,
      idType: type,
      required
    };
    mutation({ variables: { input } })
  }

  return (
    <>
      <CreateCategory refetch={reload} />
      <Segment style={{ marginTop: 20 }}>
        <h4>Crear Pregunta</h4>
        <div style={{ textAlign: 'center' }}>
          <Button
            circular
            onClick={() => setOpen(true)}
            style={{ backgroundColor: "#045A73", color: "white" }}
            icon="add" />
        </div>
      </Segment>
      <h3>Categorías</h3>
      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear pregunta</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              label="Nombre"
              style={{ maxHeight: 50, minHeight: 50 }}
              value={name}
              onChange={(e, { value }) => setName(value)}
              placeholder="Agregar Nombre" />
            <Form.TextArea
              label="Enunciado"
              style={{ maxHeight: 50, minHeight: 50 }}
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)}
              placeholder="Agregar Enunciado" />
            <Form.Dropdown
              selection
              label="Tipo de pregunta"
              search
              value={type}
              onChange={(e, { value }) => setType(value)}
              loading={loading}
              placeholder="Seleccionar un tipo"
              error={error}
              options={options} />
            <Form.Dropdown
              selection
              label="Categoría"
              search
              value={category}
              onChange={(e, { value }) => setCategory(value)}
              loading={loading}
              placeholder="Seleccionar un tipo"
              error={error}
              options={data?.categories?.items || []} />
            <Form.Checkbox
              checked={required}
              onChange={(e, { checked }) => setRequired(checked)}
              toggle
              label="Obligatoria" />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={!name.trim() || !enunciated.trim() || !type.trim()}
            loading={loadingSave}
            onClick={handleMutation}
            content="Crear" />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};


const DATA = gql`
  query {
    typeOptions: parameterValueList(
      page: { number: 1, limit: 100 },
      filter: { status: ACTIVE, idParameter: "1" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    categories: categoryList(
      page: {number: 1, limit: 100},
      filter: { status: ACTIVE }
    ) {
      items {
        value:id
        text: name
        key: id
      }
    }
  }
`;


const CREATE_QUESTION = gql`
  mutation($input: CreateQuestionInput!){
    createQuestion(input: $input) {
      id
    }
  }
`;


export default CreateQuestion;