import { useQuery, gql } from "@apollo/client";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import Selector from "../../Permissions/Selector";
import DownloadReportList from "./DownloadList";
import DownloadReport from "./DownloadReport";
import ReportDetail from "./ReportDetail";
//import ReportDetail from "./ReportDetail";

const Offers = () => {
  return (
    <>
      <Content />
    </>
  );
};

const Content = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState({});
  const variables = {
    filter: filter,
    page: { number: currentPage, limit: 10 },
  };
  const { loading, error, data, refetch } = useQuery(POLLSETR_LIST, {
    variables,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [filter]);

  const columns = [
    {
      header: "Primer nombre",
      accessor: "firstName",
    },
    {
      header: "Segundo nombre",
      accessor: "secondName",
    },
    {
      header: "Primer Apellido",
      accessor: "surname",
    },
    {
      header: "Segundo Apellido",
      accessor: "secondSurname",
    },
    {
      header: "Número de telefono",
      accessor: "phone",
    },
    {
      header: "Género",
      accessor: "gender.name",
    },
    {
      header: "Documento",
      accessor: "identification",
    },
    {
      header: "# Casa",
      render: (item) => {
        return item?.familyLeader?.houseNumber || "";
      },
    },
    {
      header: "Lider familiar",
      render: (item) => {
        return item?.familyLeader?.name || "";
      },
    },
    {
      header: "Nacionalidad",
      render: (item) => {
        return item?.nationality?.name || "";
      },
    },
    {
      header: "Dirección",
      render: (item) => {
        return item?.address || "";
      },
    },
    {
      header: "Género",
      render: (item) => {
        return item?.gender?.name || "";
      },
    },
    {
      header: "Fecha de nacimiento",
      accessor: "birthDate",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : ""),
    },
    {
      header: "Edad",
      accessor: "birthDate",
      render: (date) => (date ? moment().diff(moment(date), "years") : ""),
    },
    {
      header: "Fecha de Inicio",
      accessor: "offerHeader.answeDate",
    },
    {
      header: "Fecha de fin",
      accessor: "offerHeader.endDate",
    },
    {
      header: "Programa",
      accessor: "offerHeader.offer.program.name",
    },
    {
      header: "Tipo de documento de identidad",
      render: (item) => {
        return item?.typeIdentification?.name || "";
      },
    },
    {
      header: "Encuestador",
      render: (item) => {
        return item?.familyLeader?.Encuestador?.name?.includes("undefined")
          ? ""
          : item?.familyLeader?.Encuestador?.name;
      },
    },
    {
      header: "Detalle",
      render: (info) => <ReportDetail id={info?.id} member={info?.name} />,
    },
  ];

  return (
    <>
      <Form>
        <Form.Group>
          <Label
            content={`Total beneficiarios (${
              data?.FilterFamilyMembers?.count || 0
            })`}
          />
          <DownloadReport filter={filter} />
        </Form.Group>
        <Form.Field>
          <Selector
            loading={!data ? true : false}
            error={error}
            value={filter.offerId}
            onChange={(e, { value }) => {
              setFilter({ offerId: value });
            }}
            data={
              data?.offerList?.items.map((e) => ({
                value: e.id,
                text: e.name,
                key: e.name,
              })) || []
            }
            placeholder="Filtrado por oferta"
          />
        </Form.Field>
        <Form.Field>
          <DownloadReportList filter={filter} />
        </Form.Field>
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.FilterFamilyMembers?.totalPages || 0}
        data={data?.FilterFamilyMembers?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const POLLSETR_LIST = gql`
  query ($page: PageInput!, $filter: FamilyMemberFilter) {
    offerList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        description
        status
        inCharge {
          id
          name
        }
      }
    }
    FilterFamilyMembers(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        firstName
        secondName
        surname
        phone
        secondSurname
        id
        identification
        residesInVillaCaracas
        exitDate
        offerHeader {
          id
          answeDate
          endDate
          offer {
            id
            name
            program {
              id
              name
            }
          }
        }
        familyLeader {
          id
          name
          documents {
            id
            active
            identification
            typeIdentification {
              id
              name
            }
          }
        }
        mail
        phone
        birthDate
        gender {
          id
          name
        }
        maritalStatus {
          id
          name
        }
        typeIdentification {
          id
          name
        }
        regularization {
          id
          name
        }
        nationality {
          id
          name
        }
        socialSecurity {
          id
          name
        }
        educativeLevel {
          id
          name
        }
        occupation {
          id
          name
        }
        inabilities {
          id
          name
        }
        title
        familyLeader {
          id
          identification
          name
          houseNumber
          Encuestador {
            name
          }
        }
        lastGradeReached
        educativeEstablishment
        jobPerformed
        eps
        neighborhood
        address
        expeditionDocumentDate
        expirationDocumentDate
        responseQuestion {
          id
        }
      }
    }
  }
`;

export default Offers;
