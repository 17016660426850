import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";

const PollsterDetail = ({ id, filter, pollster }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          compact
          size="tiny"
          icon="arrow circle right"
          onClick={() => setOpen(true)}
        />
      }
    >
      <Modal.Header>Detalle de encuestas</Modal.Header>
      <Content id={id} filter={filter} pollster={pollster} />
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

const Content = ({ id, filter, pollster }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    id,
    filter,
    page: { number: currentPage, limit: 10 },
  };
  const { loading, error, data } = useQuery(POLLSTER_DETAIL, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Miembro familiar",
      accessor: "familyMenmber",
      render: ({ firstName, secondName, surname, secondSurname }) =>
      `${firstName || ''} ${secondName ? ` ${secondName}` : ''} ${surname ? ` ${surname}` : ''} ${secondSurname ? ` ${secondSurname}` : ''}`,
    },
    {
      header: "Documento",
      accessor: "familyMenmber.identification",
    },
    {
      header: "Lider familiar",
      accessor: "familyMenmber.familyLeader.name",
    },
    {
      header: "Documento lider",
      accessor: "familyMenmber.familyLeader.identification",
    },
    {
      header: "#Casa",
      accessor: "familyMenmber.familyLeader.houseNumber",
    },
    {
      header: "Fecha inicio",
      accessor: "startDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "- - - -",
    },
    {
      header: "Fecha fin",
      accessor: "endDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "- - - -",
    },
    {
      header: "Estado encuesta",
      accessor: "answered",
      render: (info) => (info ? "Completado" : "Incompleto"),
    },
  ];

  return (
    <>
      <div style={{ paddingTop: 10, paddingLeft: 20 }}>
        <Form>
          <Form.Group>
            <Form.Field>
              <Label content={`Encuestador (${pollster})`} />
            </Form.Field>
            <Form.Field>
              <Label
                content={`Usuarios encuestados (${
                  data?.detailPollster?.count || 0
                })`}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <Modal.Content scrolling>
        <CustomTable
          currentPage={currentPage}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          totalPages={data?.detailPollster?.totalPages || 0}
          data={data?.detailPollster?.items || []}
          loading={loading}
          error={error}
          columns={columns}
        />
      </Modal.Content>
    </>
  );
};

const POLLSTER_DETAIL = gql`
  query ($id: ID!, $filter: ReportPollsterFilterInput!, $page: PageInput!) {
    detailPollster(id: $id, filter: $filter, page: $page) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        answered
        startDate
        endDate
        familyMenmber {
          id
          isLeader
          firstName
          secondName
          surname
          secondSurname
          identification
          familyLeader {
            id
            name
            identification
            houseNumber
          }
        }
      }
    }
  }
`;

export default PollsterDetail;
