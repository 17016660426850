import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import CreatePeriod from "./CreatePeriod";
import UpdatePeriod from "./UpdatePeriod";

const PeriodList = ({ permissions, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [fakeName, setFakeName] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const variables = {
    page: { number: currentPage, limit: 10 },
    type: type,
  };
  if (name.trim()) variables.name = name;
  if (idFilter.trim()) variables.idFilter = idFilter;
  const { data, loading, error, refetch } = useQuery(PERIODS, {
    variables,
    fetchPolicy: "network-only",
  });

  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");

  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");

  const columns = [
    {
      header: "Periodo",
      accessor: "name",
    },
    {
      header: "Fecha inicio",
      accessor: "startDate",
    },
    {
      header: "Fecha fin",
      accessor: "endDate",
    },
  ];

  switch (type) {
    case "SURVEY":
      columns.push({
        header: "Encuesta",
        accessor: "survey.name",
      });
      break;
    case "OFFER":
      columns.push({
        header: "Oferta",
        accessor: "offer.name",
      });
      break;
    case "FAMILYPLAN":
      columns.push({
        header: "Plan familiar",
        accessor: "familyPlan.name",
      });
      break;
    default:
      break;
  }

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (period) => (
        <UpdatePeriod
          type={type}
          refetch={refetch}
          period={period}
          dataDropdown={
            type === "SURVEY"
              ? data?.surveys || []
              : type === "OFFER"
              ? data?.offers || []
              : data?.familyPlans || []
          }
        />
      ),
    });

  return (
    <div>
      <Form>
        <Form.Group inline>
          {CREATE && (
            <CreatePeriod
              refetch={refetch}
              type={type}
              dataSelect={
                type === "SURVEY"
                  ? data?.surveys || []
                  : type === "OFFER"
                  ? data?.offers || []
                  : data?.familyPlans || []
              }
            />
          )}
          <Form.Field>
            <Label content={`Cantidad (${data?.periodList?.count || 0})`} />
          </Form.Field>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Dropdown
            size="mini"
            options={
              type === "SURVEY"
                ? data?.surveys
                : type === "OFFER"
                ? data?.offers
                : data?.familyPlans
            }
            placeholder={
              type === "SURVEY"
                ? "Filtrar por encuesta"
                : type === "OFFER"
                ? "Filtrar por oferta"
                : "Filtrar por plan familiar"
            }
            value={idFilter}
            label={
              type === "SURVEY"
                ? "Encuesta"
                : type === "OFFER"
                ? "Oferta"
                : "Plan familiar"
            }
            clearable
            onChange={(e, { value }) => setIdFilter(value)}
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.periodList?.totalPages || 0}
        data={data?.periodList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const PERIODS = gql`
  query ($page: PageInput!, $name: String, $idFilter: ID, $type: Type) {
    periodList(page: $page, name: $name, idFilter: $idFilter, type: $type) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        startDate
        endDate
        status
        survey {
          id
          name
          id
        }
        offer {
          id
          name
          id
        }
        familyPlan {
          id
          name
          id
        }
      }
    }
    surveys {
      value: id
      text: name
      key: id
    }
    offers {
      value: id
      text: name
      key: id
    }
    familyPlans {
      value: id
      text: name
      key: id
    }
  }
`;

export default PeriodList;
