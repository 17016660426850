import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import { getData, parseStatusDownload } from "../../../Utils";

const URL_SERVER =
  process.env.NODE_ENV === "production"
    ? "https://pafami.com:3000/"
    : "http://localhost:4000/";

const DownloadReportList = () => {
  return (
    <Modal
      trigger={<Button circular icon="list" content="Lista de descargas" />}
    >
      <Modal.Header>Lista de descargas</Modal.Header>
      <Modal.Content scrolling>
        <Content />
      </Modal.Content>
    </Modal>
  );
};

const Content = () => {
  const user = getData("meBid");
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingList, setLoadingList] = useState(false);
  const [statusDownload, setStatusDownload] = useState("");
  const variables = { page: { number: currentPage, limit: 5 } };
  if (user?.id) variables.idUser = user?.id;
  if (statusDownload.trim()) variables.statusDownload = statusDownload;
  const { loading, error, data, refetch } = useQuery(DOWNLOAD_REPORT_LIST, {
    variables,
    fetchPolicy: "network-only",
  });

  const downloadFile = (url, fileName) => {
    const button = document.createElement("a");
    button.style.display = "none";
    button.href = URL_SERVER + url;
    button.target = "_blank";
    button.download = fileName;
    document.body.appendChild(button);
    button.click();
  };

  const columns = [
    {
      header: "Nombre",
      accessor: "root",
      render: (root) => root?.replace("reports/", ""),
    },
    {
      header: "Estado",
      accessor: "statusDownload",
      render: (statusDownload) => parseStatusDownload(statusDownload),
    },
    {
      header: "Fecha de creación",
      accessor: "createAt",
      render: (createAt) => moment(createAt).format("DD-MM-YYYY h:mm:ss A"),
    },
    {
      header: "Estado",
      render: (info) => (
        <Button
          icon="file excel"
          content="Descargar"
          size="mini"
          disabled={info?.statusDownload === "INPROGRESS"}
          onClick={() =>
            downloadFile(info?.root, info?.root?.replace("reports/", ""))
          }
        />
      ),
    },
  ];

  const reload = async () => {
    setLoadingList(true);
    await refetch();
    setLoadingList(false);
  };

  return (
    <>
      <Form>
        <Form.Group inline>
          <Form.Dropdown
            clearable
            value={statusDownload}
            placeholder="Filtrar por estado"
            onChange={(e, { value }) => setStatusDownload(value)}
            options={[
              { text: "En progreso", value: "INPROGRESS", key: 1 },
              { text: "Generado", value: "GENDERED", key: 2 },
            ]}
            label="Estado"
          />
          <Form.Button
            size="mini"
            icon="redo"
            content="Actualizar"
            onClick={reload}
          />
        </Form.Group>
      </Form>
      <CustomTable
        loading={loading || loadingList}
        error={error}
        columns={columns}
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.reportsDownload?.totalPages || 0}
        data={data?.reportsDownload?.items}
      />
    </>
  );
};

const DOWNLOAD_REPORT_LIST = gql`
  query ($page: PageInput!, $idUser: ID, $statusDownload: StatusDownload) {
    reportsDownload(
      page: $page
      idUser: $idUser
      statusDownload: $statusDownload
      reportType: SURVEY
    ) {
      count
      totalPages
      page {
        number
        limit
      }
      items {
        id
        root
        createAt
        user {
          id
          name
        }
        status
        statusDownload
      }
    }
  }
`;

export default DownloadReportList;
