import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateDocumentFamilyLeader = ({ user, setOpen }) => {
  const [identification, setIdentification] = useState(user?.identification || "");
  const [idTypeIdentification, setIdTypeIdentification] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado la identificacón de manera exitosa.'
    }).then(() => setOpen(false))
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_DOCUMENT_FAMILY_LEADER, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      id: user?.id,
    };

    if (identification !== user?.identification) input.identification = identification;
    if (idTypeIdentification.trim()) input.idTypeIdentification = idTypeIdentification;

    mutation({ variables: { input } });
  }
  return (
    <>
      <Form>
        <Form.Input
          required
          label="Identificación"
          value={identification}
          onChange={(e, { value }) => setIdentification(value)} />
        <TypeIdentification
          label="Tipo de identificación"
          value={idTypeIdentification}
          onChange={(e, { value }) => setIdTypeIdentification(value)} />
      </Form>
      <div style={{ marginTop: 20 }}>
        <Button
          content="Guardar"
          style={{ backgroundColor: "#045A73", color: "white" }}
          loading={loading}
          disabled={!identification?.trim() || loading}
          onClick={handleMutation} />
        <Button
          content="Cancelar"
          onClick={() => setOpen(false)} />
      </div>
    </>
  )
}

const TypeIdentification = ({ onChange, value, label }) => {
  const { loading, error, data } = useQuery(TYPES_IDENTIFICATION);

  return (
    <Form.Dropdown
      selection
      label={label}
      search
      value={value}
      clearable
      loading={loading}
      error={error}
      onChange={onChange}
      options={data?.parameter?.parameterValues || []} />
  );
}

const TYPES_IDENTIFICATION = gql`
  query{
    parameter(id: "3") {
      id
      name
      parameterValues {
        key: id
        text: name
        value: id
      }
    }
  }
`;

const UPDATE_DOCUMENT_FAMILY_LEADER = gql`
  mutation($input: UpdateFamilyLeaderInput!) {
    updateFamilyLeader(input: $input) {
      id
      identification
      familyMenmbers {
        id
        typeIdentification {
          id
          name
        }
      }
      documents {
        id
        identification
        active
      }
    }
  }
`;

export default UpdateDocumentFamilyLeader;