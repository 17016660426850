import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import TypeQuestion from "./TypeQuestion";

const CreateQuestion = ({ idCategory, refetch, idOption, idFamilyPlan }) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [enunciated, setEnunciated] = useState("");
  const [required, setRequired] = useState(false);
  const [idType, setIdType] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    setEnunciated("");
    setIdType("");
    setRequired(false);
    setLoading(false);
    setOpen(false);
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_QUESTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      name,
      enunciated,
      required,
      idType
    }
    if (idFamilyPlan) input.idFamilyPlan = idFamilyPlan;
    if (idOption) {
      input.idOption = idOption;
    } else {
      input.idCategory = idCategory;
    }
    mutation({
      variables: {
        input
      }
    });
  }
  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      content={
        <div>
          <Form>
            <Form.TextArea
              value={name}
              onChange={(e, { value }) => setName(value)}
              style={{ minHeight: 60, maxHeight: 60 }}
              label="Nombre" />
            <Form.TextArea
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)}
              style={{ minHeight: 60, maxHeight: 60 }}
              label="Enunciado" />
            <TypeQuestion
              value={idType}
              onChange={(e, { value }) => setIdType(value)}
              label="Tipo pregunta" />
            <Form.Checkbox
              toggle
              checked={required}
              onChange={(e, { checked }) => setRequired(checked)}
              label="Obligatoria" />
            <Button
              loading={loading}
              disabled={!name.trim() || !enunciated.trim() || !idType.trim() || loading}
              onClick={handleMutation}
              content="Crear pregunta" />
          </Form>
        </div>
      }
      on='click'
      positionFixed
      trigger={
        <Segment style={{ textAlign: 'center' }}>
          <Button onClick={() => setOpen(true)} circular icon="add" />
        </Segment>
      }
    />

  );
}

const CREATE_QUESTION = gql`
  mutation($input: CreateFamilyPlanQuestionInput!){
    createFamilyPlanQuestion(input: $input) {
      id
    }
  }
`;

export default CreateQuestion;